import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from '@material-ui/core/Typography';
import axios from "axios"
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/test.js";
import FileInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
//React Hook Form
import { useForm, ErrorMessage, Controller } from "react-hook-form";
//Redux
import { connect } from "react-redux";
import propTypes from "prop-types";
import { GetSubCategories, EditBook, ViewSubcategories, GetCategories, GetAllCategories, GetSubCategory } from "../../actions/Books.js"
import { ViewBookPage } from "../../actions/Books.js"
import CircularProgress from '@material-ui/core/CircularProgress';

import Keys from "../../config/key.js";
axios.defaults.baseURL = Keys.base_url;

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
}));


const Editbook = (props) => {

  const { handleSubmit, errors, control } = useForm();

  const classes = useStyles();
  const [error, setError] = React.useState("")

  const [subcategory, setSubCategory] = React.useState("");
  const [subcategories, setSubCategories] = React.useState([]);

  const [category, setCategory] = React.useState("");
  const [categories, setCategories] = React.useState("");

  const [image, setPoster] = React.useState(null);
  const [location, setLocation] = React.useState("")
  const [isChanged, SetChange] = React.useState(null)
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [books, setBook] = React.useState([])
  const [bookloading, setBookLoading] = React.useState(true)
  const [author_id, setAuthorid] = React.useState()
  const [id, setId] = React.useState();
  const [loader, setLoader] = React.useState(null);
  const [disable, setVisibility] = React.useState(false);
  const [type, setType] = React.useState("");
  const [tags, setTags] = React.useState([])
  const [language, setLang] = React.useState(null);
  const [free, setFree] = React.useState(false);
  const [extractPages, setExtractPages] = React.useState(0);
  const [sample_type, setSampleType] = React.useState("URL");
  const [sample_book_file, setSampleBookFile] = React.useState(null);
  const [sample_url, setSampleUrl] = React.useState('');
  const [categoryFreePurchase, setCategoryFreePurchase] = React.useState(0);

  useEffect(() => {
    props.GetAllCategories();
  }, [])

  useEffect(() => {
    props.ViewBookPage(props.match.params.id, props.match.params.type);
  }, [props.auth])

  const handleChange = event => {
    setSubCategory(event.target.value);
  };

  useEffect(() => {
    setBook(props.items.Books);
    setBookLoading(false);

    const { Books } = props.items

    Books.map((book) => {

      console.log('sfsf book', book)
      setType(book.type)
      setId(book.id);
      setAuthorid(book.author_id)
      setName(book.book_name)
      setLang(book.lang);
      setCategory(book.type == "Book" ? book.Category_id : book.Archive_Category_id);
      props.GetSubCategory(book.type == "Book" ? book.Category_id : book.Archive_Category_id);
      setSubCategory(book.type == "Book" ? book.parent_id : book.Archive_parent_id);
      setSampleType(book.sample_book_type)
      setFree(book.is_book_free)
      setSampleUrl(book.sample_url)
      setExtractPages(book.extract_pages == undefined ? 0 : book.extract_pages);
      setCategoryFreePurchase(book.category_id_Purchase == undefined ? 0 : 1);

      //props.ViewSubcategories(book.Category_id)
      if (book.tags) {
        setTags(book.tags)
      }
    })

  }, [props.items.Books])

  const EditPoster = () => {
    const link = "/admin/ViewBook/" + type + "/" + id + "/edit/" + name + "/poster";
    props.history.push(link)
  }

  const handleFreeChange = (event) => {
    document.getElementById("password").value = ''
    setFree(event.target.value);
  }

  const handleSampleBookChange = (event) => {
    setSampleType(event.target.value);
  }

  const onSubmit = (item) => {

    setLoader(
      <CircularProgress visibility="hidden" color="secondary" />
    )

    if (sample_type == "URL") {

      setVisibility(true)
      item.subcategory_id = subcategory;
      item.id = id;
      item.type = type;
      item.language = language;
      item.cat_id = category;
      item.is_book_free = free
      item.sample_url = sample_url
      item.category_id_Purchase = categoryFreePurchase
      //add subcategory name

      if (props.items.Categories) {

        item.category = props.items.Categories.find(o => o.id === category).category_name
      }

      if (props.items.SubCategories) {

        item.subcategory = props.items.SubCategories.find(o => o.id === subcategory).category_name
      }

      props.EditBook(item, props.history);
    }
    else if (sample_type == "File") {

      if (sample_book_file == null) {
        item.subcategory_id = subcategory;
        item.id = id;
        item.type = type;
        item.language = language;
        item.cat_id = category;
        item.is_book_free = free
        item.sample_url = sample_url;
        item.extract_pages = extractPages;
        item.category_id_Purchase = categoryFreePurchase

        if (props.items.Categories) {

          item.category = props.items.Categories.find(o => o.id === category).category_name
        }

        if (props.items.SubCategories) {

          item.subcategory = props.items.SubCategories.find(o => o.id === subcategory).category_name
        }

        props.EditBook(item, props.history);
      }
      else {

        let data = new FormData();
        data.append('sample_book_file', sample_book_file);
        data.append('is_free', free);
        data.append('book_name', name);

        axios.post("/upload/sampleBookFile", data, {
          headers: {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data`,
          }
        })
          .then((response) => {

            setVisibility(true)
            item.subcategory_id = subcategory;
            item.id = id;
            item.type = type;
            item.language = language;
            item.cat_id = category;
            item.is_book_free = free
            item.sample_url = response.data.location
            item.category_id_Purchase = categoryFreePurchase

            if (props.items.Categories) {

              item.category = props.items.Categories.find(o => o.id === category).category_name
            }

            if (props.items.SubCategories) {

              item.subcategory = props.items.SubCategories.find(o => o.id === subcategory).category_name
            }

            props.EditBook(item, props.history);

          }).catch((error) => {
            console.log(error)
          });
      }
      
    }
    else {
      setVisibility(true)

      item.id = id;
      item.type = type;
      item.language = language;
      item.cat_id = category;
      item.subcategory_id = subcategory;
      item.is_book_free = free
      item.sample_url = sample_url == undefined ? '' : sample_url
      item.extract_pages = parseInt(item.extract_pages)
      item.pages = parseInt(item.pages)
      item.category_id_Purchase = categoryFreePurchase

      // Need to chenge category and subcategory name as well

      if (props.items.Categories) {

        item.category = props.items.Categories.find(o => o.id === category).category_name
      }

      if (props.items.SubCategories) {

        item.subcategory = props.items.SubCategories.find(o => o.id === subcategory).category_name
      }

      props.EditBook(item, props.history);

    }
  };

  useEffect(() => {
    setVisibility(false)
    setLoader(null)
    setError(props.errors)
  }, [props.errors])

  const HandleSampleFileInput = event => {
    event.preventDefault();
    setSampleBookFile(event.target.files[0])
    setVisibility(false)
  }

  useEffect(() => {
    setCategories(props.items.Categories);

    if (books.length > 0) {
      props.GetSubCategory(books[0].type == "Book" ? books[0].Category_id : books[0].Archive_Category_id);
    }
  }, [props.items.Categories])

  useEffect(() => {
    setSubCategories(props.items.SubCategories);
    //setSubCategory(books[0].parent_id)
  }, [props.items.SubCategories])

  // useEffect(() => {
  //   setSubCategories(props.subcategories.Subcategories);
  // }, [props.subcategories.Subcategories])


  const handlelangChange = (event) => {
    setLang(event.target.value);
  }

  const handleCatChange = event => {
    console.log('handleCatChange fired')
    setCategory(event.target.value);
    props.GetSubCategory(event.target.value)
  };


  //languages to be selected by user

  const languages = [
    "English",
    "Hindi",
    "Bengali",
    "Marathi",
    "Gujrati",
    "Tamil",
    "Telugu",
    "Kannada",
    "Malayalam",
    "Punjabi",
    "Odia",
    "Urdu",
    "Nepali"
  ]

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>Edit Book</h2>
                <h3 className={classes.cardCategoryWhite}>Edit Book Details</h3>
              </CardHeader>
              {books.map((book) =>
                <CardBody>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          onChange={handleCatChange}
                        >
                          {categories && categories.map((cat) =>
                            <MenuItem value={cat.id}>{cat.category_name}</MenuItem>
                          )}

                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={subcategory}
                          onChange={handleChange}
                          displayEmpty={false}
                        >

                          {subcategories && subcategories.map((cat) =>
                            <MenuItem value={cat.id}>{cat.category_name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Is Book Free?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="password"
                          name="isFree"
                          value={free}
                          onChange={handleFreeChange}
                        >

                          <MenuItem value={false}>{"Paid"}</MenuItem>
                          <MenuItem value={true}>{"Free"}</MenuItem>

                        </Select>
                      </FormControl>

                      {free == false ?
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Sample Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="sample_type"
                            value={sample_type}
                            onChange={handleSampleBookChange}
                          >
                            <MenuItem value={"Extract"}>{"Extract from Book"}</MenuItem>
                            <MenuItem value={"URL"}>{"URL"}</MenuItem>
                            <MenuItem value={"File"}>{"File"}</MenuItem>

                          </Select>
                        </FormControl>
                        :
                        <></>
                      }

                      {free == false && sample_type == "URL" ?
                        <>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="URL of Smaple Book "
                              id="url"
                              name="sample_url"
                              control={control}
                              formControlProps={{
                                fullWidth: true
                              }}
                              value={sample_url}
                              required={true}
                            />
                          </GridItem>
                        </>
                        :
                        <></>
                      }

                      {free == false && sample_type == "File" ?
                        <GridItem xs={12} sm={12} md={12}>
                          <InputLabel style={{ color: "#AAAAAA" }}>
                            Upload Book
                          </InputLabel>
                          <FileInput
                            id="sample-book"
                            type="file"
                            control={control}
                            name="sample_book"
                            onChange={HandleSampleFileInput}
                            formControlProps={{
                              fullWidth: true
                            }}
                            required={true}

                          />
                        </GridItem>
                        :
                        <></>
                      }

                      {free == false && sample_type == "Extract" ?
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            type="number"
                            labelText="Extract pages"
                            id="extract_pages"
                            name="extract_pages"
                            control={control}
                            value={extractPages}
                            formControlProps={{
                              fullWidth: true
                            }}
                            required={true}
                          />

                        </GridItem>
                        :
                        <></>
                      }

                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Name of the Book"
                          id="book_name"
                          name="name"
                          value={book.book_name}
                          control={control}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>


                      <CustomInput
                        labelText="Name of the Author"
                        id="Author_name"
                        name="author"
                        value={book.author}
                        control={control}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  {free == false ?

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Book Original Price"
                          id="orig_price"
                          name="orig_price"
                          type="number"
                          value={book.orig_price}
                          control={control}
                          formControlProps={{
                            fullWidth: true
                          }}
                          required={true}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Book Discounted Price"
                          id="discounted_price"
                          name="discounted_price"
                          type="number"
                          value={book.discounted_price}
                          control={control}
                          formControlProps={{
                            fullWidth: true
                          }}
                          required={true}
                        />
                      </GridItem>


                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Book Pages"
                          id="pages"
                          name="pages"
                          type="number"
                          value={book.pages}
                          control={control}
                          formControlProps={{
                            fullWidth: true
                          }}
                          required={false}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Book Author"
                          id="author"
                          name="book_author"
                          type="text"
                          value={book.book_author}
                          control={control}
                          formControlProps={{
                            fullWidth: true
                          }}
                          required={false}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Book Publisher"
                          id="publisher"
                          name="publisher"
                          type="text"
                          value={book.publisher}
                          control={control}
                          formControlProps={{
                            fullWidth: true
                          }}
                          required={false}
                        />
                      </GridItem>
                    </GridContainer>

                    :
                    <></>
                  }
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl style={{ marginTop: "25px", width: "100px" }} className={classes.formControl}>
                        <InputLabel id="select-lang">
                          Language
                        </InputLabel>
                        <Select
                          labelId="select-lang"
                          id="demo-simple-select-lang"
                          value={language}
                          onChange={handlelangChange}
                        >
                          {languages.map((language) =>
                            <MenuItem value={language}>{language}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Tags(provide comma seperated tags)"
                        id="tags"
                        name="tags"
                        required={false}
                        value={tags.join(",")}
                        control={control}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>



                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Enter the Description of the book"
                        id="Description"
                        name="desc"
                        value={book.description}
                        control={control}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 5
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              )}

              <CardFooter>

                <Button disabled={disable} type="submit" color="rose">
                  Update
                </Button>
                {loader}
                {error.message && (
                  <Typography color="textSecondary" variant="subtitle2" gutterBottom>
                    {error.message}
                  </Typography>
                )}
                <Button onClick={EditPoster} color="rose">
                  Edit Poster
                </Button>

              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );

}

Editbook.propTypes = {
  auth: propTypes.object.isRequired,
  GetSubCategories: propTypes.func.isRequired,
  GetAllCategories: propTypes.func.isRequired,
  GetSubCategory: propTypes.func.isRequired,
  ViewBookPage: propTypes.func.isRequired,
  EditBook: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  poster: state.poster,
  items: state.items,
  categories: state.categories,
  subcategories: state.subcategories
});

export default connect(mapStateToProps, { EditBook, GetSubCategories, ViewBookPage, ViewSubcategories, GetCategories, GetAllCategories, GetSubCategory })(Editbook);