import React, { useState, useEffect } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import CategoryCard from "components/Card/CategoryCard.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import propTypes from "prop-types";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { NavLink } from "react-router-dom";
//Icons
import LibraryBooksSharpIcon from "@material-ui/icons/LibraryBooksSharp";
import CategorySharpIcon from "@material-ui/icons/CategorySharp";
import GroupSharpIcon from "@material-ui/icons/GroupSharp";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//Style
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { connect } from "react-redux";
import { GetCategories, Getstats, clearCategories, DeleteCategory } from "../../actions/Books.js"
import LinearProgress from '@material-ui/core/LinearProgress';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import { Modal } from 'react-bootstrap';

const keys = require("../../config/key")

const Dashboard = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [Loading, setLoading] = React.useState(true)
  const [categories, setCategories] = React.useState([]);
  const [usercount, setUsercount] = React.useState(0);
  const [categorycount, setCategorycount] = React.useState(0);
  const [bookcount, setBookcount] = React.useState(0);
  const [time, setTime] = React.useState(0);
  const [files, setFileCount] = React.useState(0)

  const [arr, setArr] = React.useState([]);
  const [index, setIndex] = React.useState(-1);
  const [disabled, setNext] = React.useState(true)
  const [prevItems, setPrev] = React.useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [data, setData] = useState([]);
  const [modalPassword, setModalPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")


  const setModalIsOpenToTrue = (data) => {
    console.log(data)
    setData(data)
    setModalValue('')
    setModalIsOpen(true)
    setPasswordError("")
    setModalPassword("")
  }
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
    setPasswordError("")
    setModalPassword("")
  }
  const valueChange = (event) => {
    document.getElementById("lead").style.display = "block"
    let val = event.target.value;
    setModalValue(val);
    if (val == "confirm") {
      document.getElementById("confirm").disabled = false;
    }
    else {
      document.getElementById("confirm").disabled = true;
    }
  }
  const removeCategory = (data) => {
    console.log(data)
    document.getElementById("confirm").disabled = true;
    if (modalPassword === keys.passwordDeleteCategory) {
      setPasswordError("")
      DeleteSelectedCategory(data)
    } else {
      setPasswordError("Password is wrong")
      document.getElementById("confirm").disabled = false
      setModalPassword("")
    }
  }

  const loadmore = () => {
    console.log(arr);
    console.log(index);
    var lastIndexKey = arr[index];
    setPrev(categories);
    props.GetCategories(lastIndexKey)

  }

  const DeleteSelectedCategory = (data) => {
    console.log(data);
    props.DeleteCategory(data, props.history)
  }


  useEffect(() => {
    if (!props.auth.isAdmin) {
      props.history.push("/admin/user-new-dashboard")
    } else {
      var items = {};
      props.clearCategories();
      props.GetCategories(items);
      props.Getstats();
    }
  }, [props.auth])

  useEffect(() => {
    const { stats } = props.stats;
    console.log(stats)
    stats.map((key => {
      setTime(key.Date)
      setUsercount(key.UserCount);
      setCategorycount(key.CategoryCount);
      setBookcount(key.BookCount);
      setFileCount(key.filesCount)
    }))

  }, [props.stats])


  useEffect(() => {
    // Logic to disable buuton at last page
    if (!props.categories.Key) {
      //disable the button next
      setNext(true);
    } else {
      setNext(false)
    }

    //Push the next key in array
    setIndex(index + 1)
    setArr([...arr, props.categories.Key])
    prevItems.push.apply(prevItems, props.categories.Categories);
    setCategories(prevItems);
    setLoading(false);
  }, [props.categories])




  useEffect(() => {
    setLoading(props.items.loading)
    console.log(Loading)
  }, [props.items.loading])

  let Content;

  if (Loading) {
    Content = <GridContainer>
      <GridItem xs={12} sm={4} md={12}>
        <LinearProgress color="secondary" />
      </GridItem>
    </GridContainer>
  } else if (categories && categories.length > 0) {
    Content = <GridContainer>
      {categories.map((category) =>
        <GridItem xs={12} sm={6} md={3}>
          <CategoryCard
            data={category}
            image={category.poster}
            title={category.category_name}
            DeleteCategory={setModalIsOpenToTrue}
            description={category.category_Desc.slice(0, 20) + "..."}
            category_id={category.id}
            ViewLink='/admin/Dashboard/'
            EditLink="/admin/Dashboard/"
          />
        </GridItem>
      )}
      <Card>
        <CardFooter>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={disabled}
            onClick={loadmore}
          >Load more</Button>
        </CardFooter>
      </Card>

    </GridContainer>
  } else {
    Content = <GridContainer>
      <GridItem xs={12} sm={4} md={12}>
        <h1>Welcome to Book Cms</h1>
      </GridItem>
    </GridContainer>
  }


  return (
    <>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <LibraryBooksSharpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Total Books</p>
                <h3 className={classes.cardTitle}>{bookcount}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Recently Updated</div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <CategorySharpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Total categories</p>
                <h3 className={classes.cardTitle}>{categorycount}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Recently Updated</div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="warning">
                  <GroupSharpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Total Users</p>
                <h3 className={classes.cardTitle}>{usercount}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Recently Updated</div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <CloudUploadIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Total Files</p>
                <h3 className={classes.cardTitle}>{files}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Recently Updated</div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        {Content}


      </div>
      <Modal
        backdrop="static"
        keyboard={false}
        show={modalIsOpen} onHide={setModalIsOpenToFalse}>
        <Modal.Header closeButton>
          <Modal.Title>Are you absolutely sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="alert alert-warning" role="alert">
            Unexpected bad things will happen if you don't read this!
          </div>
          This action cannot be undone. This will permanently delete all the Books and Sub Categories of this category <br />
          <br />
          Please type <strong><u>confirm</u></strong> and enter the password to delete this category, Subcategories and its courses permanently.<br /><br />
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">

            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <label>Confirm Delete</label>
                <input type="text" class="form-control" value={modalValue} onChange={valueChange} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <label>Enter password</label>
                <input type="password" class="form-control" value={modalPassword} onChange={(e) => {
                  setModalPassword(e.target.value)
                  setPasswordError("")
                }} />
              </GridItem>
            </GridContainer>

            <p style={{ fontSize: "10px", color: "red" }}>{passwordError.length > 0 ? passwordError : ""}</p>
          </div>
          <p id="lead" style={{ width: "100%", display: "none" }}>
            <button class="btn btn-danger btn-sm" id="confirm" onClick={() => removeCategory(data)} href="#" role="button" style={{ width: "100%" }}>I understand the consequences, delete this category</button>
          </p>
        </Modal.Body>


      </Modal>
    </>
  );
}
Dashboard.propTypes = {
  GetCategories: propTypes.func.isRequired,
  Getstats: propTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  items: state.items,
  stats: state.stats,
  categories: state.categories
});

export default connect(mapStateToProps, { GetCategories, Getstats, clearCategories, DeleteCategory })(Dashboard);