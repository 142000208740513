import axios from "axios"
import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import propTypes from "prop-types";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import * as moment from 'moment'

//Icons
import LibraryBooksSharpIcon from "@material-ui/icons/LibraryBooksSharp";
import CategorySharpIcon from "@material-ui/icons/CategorySharp";
import GroupSharpIcon from "@material-ui/icons/GroupSharp";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Eye from "@material-ui/icons/Visibility";
import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { TablePagination } from "@material-ui/core";
import CancelOutlined from "@material-ui/icons/CancelOutlined";
import RateReview from "@material-ui/icons/RateReview";
import { Modal, Button } from "react-bootstrap"

//Style
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { connect } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
import { CircularProgress } from "@material-ui/core";
import DateConverter from "DateConverter/dateconverter";

import { GetAllPublishers } from "../../actions/purchaseAction";

const keys = require("../../config/key")

axios.defaults.baseURL = keys.base_url;

const PublisherDashboard = (props) => {

  // console.log('PublisherDashboard Props', props);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [Loading, setLoading] = React.useState(true);

  const [bookSalesCount, setBookSalesCount] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalRefund, setTotalRefund] = React.useState(0);
  const [totalPayable, setTotalPayable] = React.useState(0);
  const [PublisherDetails, setPublisherDetails] = React.useState({});

  const [arr, setArr] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(-1);
  const [prevItems, setPrev] = React.useState([]);
  const [index, setIndex] = React.useState(-1);
  const [loader, setLoader] = React.useState(null);
  const [showPublisherModal, setPublisherModal] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);

  const [total_sale, setPubTotalSale] = React.useState(0);
  const [total_refund, setPubTotalRefund] = React.useState(0);
  const [remaining_balance, setPubRemainingBalance] = React.useState(0);
  const [total_setteled, setPubTotalSetteled] = React.useState(0);
  const [total_payable, setPubTotalPayable] = React.useState(0);
  const [bookCount, setPubBookCount] = React.useState(0);
  const [pending_payment, setPubPendingPayment] = React.useState(0);
  const [downloadCount, setPubDownloadCount] = React.useState(0);
  const [viewCount, setPubViewCount] = React.useState(0);

  const [settlement_amount, setSettlementAmount] = React.useState(0);
  const [refund_amount, setRefundAmount] = React.useState(0);

  const [state, setState] = React.useState({
    columns: [
      { title: "Name", field: "name" },
      { title: "Email", field: "email" }
    ],
  });


  useEffect(() => {
    if (!props.auth.isAdmin) {
      props.history.push("/admin/user-new-dashboard")
    } else {
      props.GetAllPublishers();
    }
  }, [props.auth])

  useEffect(() => {
    GetPublisherAnalysisCount();
  }, [])

  // Used for show or hide loader
  useEffect(() => {
    setLoading(false);
    //setLoading(props.items.loading)
  }, [props.items.loading]);

  //Function to listen to new paginated data
  useEffect(() => {
    //Push the next key in array
    setIndex(index + 1);
    setArr([...arr, props.publishers]);
    setPrev([]);

    prevItems.push.apply(prevItems, props.publishers);

    // console.log('prevItems', prevItems)

    if (props.publishers != undefined)
      props.publishers.map((data) => {
        data.timestamp = DateConverter(data.timestamp);
      });

    setLoader(null);
    setData(prevItems);

  }, [props.publishers]);


  const GetPublisherAnalysisCount = () => {

    axios.get("/api/get-analytics-report")
      .then(res => {
        setBookSalesCount(res.data[0].total_number_book_sales);
        setTotalAmount(res.data[0].total_amount);
        setTotalRefund(res.data[0].total_refund);
        setTotalPayable(res.data[0].total_payable);

      })
      .catch(err => {
        console.log(err)
      });
  }

  const ViewPublisherDetails = (evt, data) => {
    axios.post('/api/get-publisher-details', {
      id: data.id,
    }).then((res) => {

      console.log('ViewPublisherDetails',res)
      setPublisherDetails(res);
      setPublisherModal(true);

      setPubTotalSale(res.data.total_sale == undefined ? 0 : res.data.total_sale);
      setPubTotalRefund( res.data.total_refund == undefined ? 0 : res.data.total_refund);
      setPubRemainingBalance( res.data.remaining_balance == undefined ? 0 : res.data.remaining_balance);
      setPubTotalSetteled( res.data.total_setteled == undefined ? 0 : res.data.total_setteled);
      setPubTotalPayable(res.data.total_payable == undefined ? 0 : res.data.total_payable);
      setPubBookCount(res.data.bookCount == undefined ? 0 : res.data.bookCount);
      setPubPendingPayment(res.data.pending_payment == undefined ? 0 : res.data.pending_payment);
      setPubDownloadCount(res.data.downloadCount == undefined ? 0 : res.data.downloadCount);
      setPubViewCount(res.data.viewCount == undefined ? 0 : res.data.viewCount);
    }, (error) => {
      console.log(error);
    });

  };

  const updateSettlement = () => {
    var creator_id = PublisherDetails.data.id;
    var sett_amnt = settlement_amount;

    if (settlement_amount > 0) {
      axios.post("/api/update-payment", {
        creator_id: creator_id,
        payment_amount: sett_amnt
      }).then((res) => {
        setPublisherModal(false);
        setSettlementAmount(0);
      }, (error) => {
        console.log(error);
      });
    }
  }

  const updateRefund = () => {

    var creator_id = PublisherDetails.data.id;
    var ref_amnt = refund_amount;
    if (refund_amount > 0) {
      axios.post("/api/update_refund_status", {
        creator_id: creator_id,
        amount: ref_amnt
      }).then((res) => {
        setPublisherModal(false);
        setRefundAmount(0);
        window.location.href = `/admin/publisher-analytics`;
      }, (error) => {
        console.log(error);
      });
    }

  }


  const getActions = () => {
    return [
      {
        icon: () => <Tooltip title="View Publisher" placement="bottom"><Eye /></Tooltip>,
        onClick: ViewPublisherDetails,
      }
    ];
  };

  let Content;

  // Show the LinearProgress or the main page content
  if (Loading) {
    Content = <GridContainer>
      <GridItem xs={12} sm={4} md={12}>
        <LinearProgress color="secondary" />
      </GridItem>
    </GridContainer>
  }
  else {

    Content = <GridContainer>
      <GridItem xs={12} sm={4} md={12}>
        <MaterialTable
          title="Buyer Lists"
          className={classes.table}
          columns={state.columns}
          data={data.map((x) => Object.assign({}, x))}
          options={{
            search: false,
            pageSize: 10,
          }}
          actions={getActions()}
          icons={
            ({ LastPage: () => <div /> },
            {
              FirstPage: () => <div />,
            })
          }
          onChangePage={(page) => {
            // console.log(page);
            // setPage(page);
            // loadmore(page);
          }}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                rowsPerPage={10}
                rowsPerPageOptions={[10]}
              />
            ),
          }}
        />
      </GridItem>
    </GridContainer>
  }

  return (
    <>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <LibraryBooksSharpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Total Book Sales</p>
                <h3 className={classes.cardTitle}>{bookSalesCount}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Recently Updated</div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <CategorySharpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Total Amount</p>
                <h3 className={classes.cardTitle}>{totalAmount}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Recently Updated</div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="warning">
                  <GroupSharpIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Total Refund</p>
                <h3 className={classes.cardTitle}>{totalRefund}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Recently Updated</div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <CloudUploadIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Total Payable</p>
                <h3 className={classes.cardTitle}>{totalPayable}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Recently Updated</div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        {/* Content for this page below */}

        {Content}

        <Modal
          show={showPublisherModal} 
          onHide={() => 
            {
              setPublisherModal(false)
              setSettlementAmount(0);
              setRefundAmount(0);
            }
          }
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <p>Publisher Details</p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="text-center">

            <div className="alert alert-danger" role="alert">
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <label><strong className="text-dark">Total Sale : </strong></label>
                  <label className="font-weight-bold text-danger">{total_sale}</label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <label><strong className="text-dark">Total Refund : </strong></label>
                  <label className="font-weight-bold text-danger">{total_refund}</label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <label><strong className="text-dark">Total Remaining Balance : </strong></label>
                  <label className="font-weight-bold text-danger">{remaining_balance}</label>
                </GridItem>
              </GridContainer>
            </div>

            <div className="alert alert-warning" role="alert">
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <label><strong className="text-dark">Total Setteled : </strong></label>
                  <label className="font-weight-bold text-danger">{total_setteled}</label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <label><strong className="text-dark">Total Payable : </strong></label>
                  <label className="font-weight-bold text-danger">{total_payable}</label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <label><strong className="text-dark">Total Pending Payment : </strong></label>
                  <label className="font-weight-bold text-danger">{pending_payment}</label>
                </GridItem>
              </GridContainer>
            </div>

            <div className="alert alert-danger" role="alert">
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <label><strong className="text-dark">Total Book Count : </strong></label>
                  <label className="font-weight-bold text-danger">{bookCount}</label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <label><strong className="text-dark">Total Download Count : </strong></label>
                  <label className="font-weight-bold text-danger">{downloadCount}</label>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <label><strong className="text-dark">Total View Count : </strong></label>
                  <label className="font-weight-bold text-danger">{viewCount}</label>
                </GridItem>
              </GridContainer>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="alert alert-warning" role="alert">
              <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <input type="number" value={settlement_amount}
                    className="form-control"
                    name="settlement_amount" placeholder="Enter Amount"
                    onChange={(e) => setSettlementAmount(e.target.value)}></input>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Button className="btn btn-info" onClick={() => updateSettlement()}>Update Settlement</Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <input type="number" value={refund_amount}
                    className="form-control"
                    name="refund_amount" placeholder="Enter Amount"
                    onChange={(e) => setRefundAmount(e.target.value)}></input>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Button className="btn btn-info" onClick={() => updateRefund()}>Update Refund</Button>
                </GridItem>
              </GridContainer>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={successModal} onHide={() => setSuccessModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <p>Response Status</p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="text-center">
            <div>SuccessFully Updated Amount</div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-danger" onClick={() => setSuccessModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
}

PublisherDashboard.propTypes = {
  GetAllPublishers: propTypes.func.isRequired
};

const mapStateToProps = (state) =>
({
  auth: state.auth,
  errors: state.errors,
  items: state.items,
  stats: state.stats,
  publishers: state.publisher.Publishers
});

export default connect(mapStateToProps, { GetAllPublishers })(PublisherDashboard);