import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from '@material-ui/core/Typography';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/test.js";
import FileInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
//React Hook Form
import { useForm, ErrorMessage, Controller } from "react-hook-form";
//Redux
import { connect } from "react-redux";
import { generatePosterUsingUserBackGround, generatePosterImage, addPoster } from "../../actions/posterAction.js"
import propTypes from "prop-types";
import { GetAllSubCategories, GetAllCategories, PostBook, clearMybooks, clearCategories, clearSubcategories, clearSubbooks } from "../../actions/Books.js"
import CircularProgress from '@material-ui/core/CircularProgress';
import { setEmitFlags } from "typescript";


import Keys from "../../config/key.js";
const axios = require('axios');
axios.defaults.baseURL = Keys.base_url;

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
}));


const AddBook = (props) => {
  const classes = useStyles();
  //React States
  const [error, setError] = React.useState("")
  const [id, setId] = React.useState("");
  const [catId, setCatId] = React.useState("");
  const [subcategories, setSubCategories] = React.useState([]);
  const [image, setPoster] = React.useState(null);
  const [disable, setVisibility] = React.useState(true);
  const { handleSubmit, errors, control, setValue } = useForm();
  const [location, setLocation] = React.useState("")
  const [isChanged, SetChange] = React.useState(null)
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [loader, setLoader] = React.useState(null)
  const [categories, setCategories] = React.useState([]);
  const [language, setLang] = React.useState("English");
  const [tags, setTags] = React.useState("");
  const [free, setFree] = React.useState(true);
  const [sample_type, setSampleType] = React.useState("URL");
  const [sample_url, setSampleUrl] = React.useState("");
  const [publisher, setPublisher] = React.useState("");
  const [book_author, setBookAuthor] = React.useState("");
  const [pages, setPages] = React.useState(0);
  const [password, setPassword] = React.useState("");
  const [orig_price, setOrigPrice] = React.useState(0);
  const [dis_price, setDisPrice] = React.useState(0);
  const [is_audible, setIsAudible] = React.useState(true);
  const [item_format, setItemFormat] = React.useState(false);
  const [extract_pages, setExtractPages] = React.useState('');

  const [sample_book_file, setSampleBookFile] = React.useState(null);
  const [image1, setImage1] = React.useState('')
  const [image2, setImage2] = React.useState('')
  const [image3, setImage3] = React.useState('')
  const [uploadPosterOption, setuploadPosterOption] = React.useState(true)
  const [selectedPoster, setSelectedPoster] = React.useState('')
  const [errorLoadingSuggestions, setErrorLoadingSuggestions] = React.useState(false)

  //Event Functions
  const handleChange = event => {
    setId(event.target.value);
  };
  const handleCatChange = event => {
    setCatId(event.target.value);
    props.GetAllSubCategories(event.target.value)
  };
  const HandleCatOpen = event => {
    props.GetAllCategories();
  }


  const imageContent = () => {
    let name = document.getElementById('book_name') ? document.getElementById('book_name').value : ""
    return <>
      <GridContainer style={{ height: "300px" }}>
        <GridItem xs={12} sm={12} md={4}>
          <div
            style={{
              position: "absolute",
              textAlign: "center",
              color: "white",
            }}
          >
            <img
              width="150"
              height="280"
              src={`${image1}`}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
                fontStyle: "bold",
                overflow: "hidden",
                msTransform: "translate(-50%, -50%)",
                fontSize: "30px",
              }}
            >
              <b>{name.substring(0, 50)}</b>
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <div
            style={{
              position: "absolute",
              textAlign: "center",
              color: "white",
            }}
          >
            <img
              width="150"
              height="280"
              src={`${image2}`}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
                fontStyle: "bold",
                overflow: "hidden",
                msTransform: "translate(-50%, -50%)",
                fontSize: "30px",
              }}
            >
              <b>{name.substring(0, 50)}</b>
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <div
            style={{
              position: "absolute",
              textAlign: "center",
              color: "white",
            }}
          >
            <img
              width="150"
              height="280"
              src={`${image3}`}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
                fontStyle: "bold",
                overflow: "hidden",
                msTransform: "translate(-50%, -50%)",
                fontSize: "30px",
              }}
            >
              <b>{name.substring(0, 50)}</b>
            </div>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <button
            onClick={(e) => {
              setSelectedPoster(image1);
            }}
            className="btn btn-primary btn-sm ml-2"
            type="button"
          >
            Select
          </button>
          <button
            onClick={async (e) => {
              await fetch("https://source.unsplash.com/random").then((res) =>
                setImage1(res.url)
              );
            }}
            className="btn btn-secondary btn-sm ml-2"
            type="button"
          >
            Another
          </button>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <button
            onClick={(e) => {
              setSelectedPoster(image2);
            }}
            className="btn btn-primary btn-sm ml-2"
            type="button"
          >
            Select
          </button>
          <button
            onClick={async (e) => {
              await fetch("https://source.unsplash.com/random").then((res) =>
                setImage2(res.url)
              );
            }}
            type="button"
            className="btn btn-secondary btn-sm ml-2"
          >
            Another
          </button>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <button
            className="btn btn-primary btn-sm ml-2"
            onClick={(e) => {
              setSelectedPoster(image3);
            }}
            type="button"
          >
            Select
          </button>
          <button
            onClick={async (e) => {
              await fetch("https://source.unsplash.com/random").then((res) =>
                setImage3(res.url)
              );
            }}
            className="btn btn-secondary btn-sm ml-2"
            type="button"
          >
            Another
          </button>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className="text-center mt-5 mb-3">
            <button
              className="btn btn-warning btn-sm ml-2"
              onClick={(e) => {
                setuploadPosterOption(true);
                setSelectedPoster('')
              }}
              type="button"
            >
              Upload Poster Background Instead
            </button>
          </div>
        </GridItem>
      </GridContainer>
    </>
  }

  const generateImages = async () => {
    try {
      await fetch("https://source.unsplash.com/random")
        .then((res) => {
          if (res.status !== 200) {
            setErrorLoadingSuggestions(true);
          } else {
            setImage1(res.url);
          }
        })
        .catch((err) => {
          throw err;
        });
      await fetch("https://source.unsplash.com/random")
        .then((res1) => {
          if (res1.status !== 200) {
            setErrorLoadingSuggestions(true);
          } else {
            setImage2(res1.url);
          }
        })
        .catch((err) => {
          throw err;
        });
      await fetch("https://source.unsplash.com/random")
        .then((res2) => {
          if (res2.status !== 200) {
            setErrorLoadingSuggestions(true);
          } else {
            setImage3(res2.url);
          }
        })
        .catch((err) => {
          throw err;
        });
    } catch (err) {
      setErrorLoadingSuggestions(true);
    }
  };

  const onSubmit = (item) => {
    console.log(item)
    // if suggestions are not loaded and user has not uploaded images then alert
    if (errorLoadingSuggestions && (image === null || image === "no image")) {
      alert("Image is required")
    } else if (catId && id) {
      setVisibility(true)
      setLoader(
        <CircularProgress visibility="hidden" color="secondary" />
      )
      if (sample_type == "File") {
        let data = new FormData();
        data.append('sample_book_file', sample_book_file);
        data.append('is_free', free);
        data.append('book_name', item.name);
        console.log(data)
        axios.post("/upload/sampleBookFile", data, {
          headers: {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data`,
          }
        })
          .then((response) => {
            console.log("Here res come")
            console.log(response)
            if (response.data.pass != undefined) {
              item.password = response.data.pass
              setPassword(item.password)
            }

            item.sample_url = response.data.location
            item.is_book_free = free
            item.sample_book_type = sample_type
            SetChange(true);
            setName(item.name);
            setDesc(item.desc);
            setAuthor(item.author);
            setTags(item.tags);
            setPublisher(item.publisher)
            setBookAuthor(item.book_author)
            setPages(item.pages)
            setSampleUrl(response.data.location)
            setOrigPrice(item.orig_price)
            setDisPrice(item.discounted_price)
            setFree(item.is_book_free)
            setSampleType(item.sample_book_type)

            console.log(item)
            let formData = new FormData();
            formData.append('selectedImage', image);
            formData.append('bookName', item.name);

            //Upload Poster to Aws s3
            console.log(image);

            setLang(language);
            if (image !== null && image !== "no image") {
              props.addPoster(formData);
            } else {
              props.generatePosterImage({
                bookName: item.name,
                backGroundImageUrl: selectedPoster !== '' ? selectedPoster : "https://source.unsplash.com/random"
              })
            }
          }).catch((error) => {
            //handle error
          });
      }
      else if (sample_type === "URL") {
        if (item.sample_url !== "" && item.sample_url !== undefined) {
          axios.post("/encrypt/url", { sample_url: item.sample_url, is_free: free, book_name: item.name })
            .then((response) => {
              console.log(response)
              if (response.data.pass != undefined) {
                item.password = response.data.pass
                setPassword(item.password)
              }

              setSampleUrl(item.sample_url)
              item.is_book_free = free
              item.sample_book_type = sample_type
              SetChange(true);
              setName(item.name);
              setDesc(item.desc);
              setAuthor(item.author);
              setTags(item.tags);

              setPublisher(item.publisher)
              setBookAuthor(item.book_author)
              setPages(item.pages)
              setOrigPrice(item.orig_price)
              setDisPrice(item.discounted_price)
              setFree(item.is_book_free)
              setSampleType(item.sample_book_type)
              setItemFormat(item.format)
              console.log(item)
              let formData = new FormData();
              formData.append('selectedImage', image);
              formData.append('bookName', item.name);

              //Upload Poster to Aws s3
              console.log(image);

              setLang(language);
              if (image !== null && image !== "no image") {
                props.addPoster(formData);
              } else {
                props.generatePosterImage({
                  bookName: item.name,
                  backGroundImageUrl: selectedPoster !== '' ? selectedPoster : "https://source.unsplash.com/random"
                })
              }
            })
        }
        else {
          item.is_book_free = free
          item.sample_book_type = sample_type
          SetChange(true);
          setName(item.name);
          setDesc(item.desc);
          setAuthor(item.author);
          setTags(item.tags);
          setSampleUrl(item.sample_url)
          setPublisher(item.publisher)
          setBookAuthor(item.book_author)
          setPages(item.pages)
          setPassword(item.password)
          setOrigPrice(item.orig_price)
          setDisPrice(item.discounted_price)
          setFree(item.is_book_free)
          setSampleType(item.sample_book_type)
          setItemFormat(item.format)
          console.log(item)
          let formData = new FormData();
          formData.append('selectedImage', image);
          formData.append('bookName', item.name);

          //Upload Poster to Aws s3
          console.log(image);

          setLang(language);
          if (image !== null && image !== "no image") {
            props.addPoster(formData);
          } else {
            props.generatePosterImage({
              bookName: item.name,
              backGroundImageUrl: selectedPoster !== '' ? selectedPoster : "https://source.unsplash.com/random"
            })
          }
        }
      }
      else {
        item.is_book_free = free
        item.sample_book_type = sample_type
        SetChange(true);
        setName(item.name);
        setDesc(item.desc);
        setAuthor(item.author);
        setTags(item.tags);
        setSampleUrl(item.sample_url)
        setPublisher(item.publisher)
        setBookAuthor(item.book_author)
        setPages(item.pages)
        setPassword(item.password)
        setOrigPrice(item.orig_price)
        setDisPrice(item.discounted_price)
        setFree(item.is_book_free)
        setSampleType(item.sample_book_type)
        setItemFormat(item.format)
        setExtractPages(item.extract_pages)
        console.log(item)
        let formData = new FormData();
        formData.append('selectedImage', image);
        formData.append('bookName', item.name);

        //Upload Poster to Aws s3
        console.log(image);

        setLang(language);
        if (image !== null && image !== "no image") {
          props.addPoster(formData);
        } else {
          props.generatePosterImage({
            bookName: item.name,
            backGroundImageUrl: selectedPoster !== '' ? selectedPoster : "https://source.unsplash.com/random"
          })
        }
      }


    }


  };

  useEffect(() => {
    fetchData()
    generateImages()
  }, [])

  const fetchData = async () => {
    await axios.get(`/api/get-info-of-current-user`).then((res) => {
      if (res.data.success) {
        if (res.data.isExternalCreator) {
          try {
            document.getElementById('Author_name').disabled = true
          } catch (e) { }
        } else {
          try {
            document.getElementById('Author_name').disabled = false
          } catch (e) { }
        }
        setValue("author", res.data.name)
        try {
          document.getElementById('label_Author_name').className = "MuiFormLabel-root MuiInputLabel-root makeStyles-labelRoot-405 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-focused Mui-focused"
        } catch (er) { }
      }
    });
  }

  const HandleFileInput = event => {
    event.preventDefault();
    setPoster(event.target.files[0])
    setVisibility(false)

  }
  const HandleSampleFileInput = event => {
    event.preventDefault();
    setSampleBookFile(event.target.files[0])
    setVisibility(false)
  }
  useEffect(() => {

    var items = {}
    if (isChanged) {
      console.log(language)
      console.log(props.poster);
      const { url } = props.poster;
      setLocation(url.location);
      items.name = name;
      items.description = desc;
      items.author = author;
      items.poster = url.location;
      items.status = "Not Uploaded Files";
      items.type = "A_Book";
      items.subcategory_id = id;
      items.language = language;
      items.tags = tags
      items.cat_id = catId;
      items.is_book_free = free
      items.sample_book_type = sample_type
      items.orig_price = orig_price
      items.discounted_price = dis_price
      items.pages = pages
      items.sample_url = sample_url
      items.book_author = book_author
      items.publisher = publisher
      items.password = password
      items.is_audible = is_audible
      items.item_format = item_format
      items.extract_pages = extract_pages

      if (props.items.Categories) {
        items.category = props.items.Categories.find(o => o.id === catId).category_name
      }
      //add subcategory name
      if (props.items.SubCategories) {
        items.subcategory = props.items.SubCategories.find(o => o.id === id).category_name
      }
      console.log(items);
      props.PostBook(items, props.history);
    }
  }, [props.poster])

  useEffect(() => {
    props.clearCategories();
    props.clearMybooks();
    props.clearSubbooks();
    props.clearSubcategories();
  }, [props.auth])

  useEffect(() => {
    setError(props.errors)
    setVisibility(false)
    setLoader(null)
  }, [props.errors])


  useEffect(() => {
    setCategories(props.items.Categories);
    //Route
  }, [props.items.Categories])



  useEffect(() => {
    setSubCategories(props.items.SubCategories);
  }, [props.items.SubCategories])


  const handlelangChange = (event) => {
    setLang(event.target.value);
  }
  const handleaudibleChange = (event) => {
    setIsAudible(event.target.value);
  }
  const handleFreeChange = (event) => {
    setFree(event.target.value);
    if (event.target.value === false) {
      setSampleType("Extract")
      setSampleBookFile("Extract")
    } else {
      setSampleType("")
      setSampleBookFile("")
    }
  }
  const handleSampleBookChange = (event) => {
    setSampleType(event.target.value);
  }
  //languages to be selected by user
  const languages = [
    "English",
    "Hindi",
    "Bengali",
    "Marathi",
    "Gujrati",
    "Tamil",
    "Telugu",
    "Kannada",
    "Malayalam",
    "Punjabi",
    "Odia",
    "Urdu",
    "Nepali"
  ]


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>Step 1</h2>
                <h3 className={classes.cardCategoryWhite}>Add Book Details</h3>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={catId}
                        onOpen={HandleCatOpen}
                        onChange={handleCatChange}
                      >
                        {categories.map((category) =>
                          <MenuItem value={category.id}>{category.category_name}</MenuItem>
                        )}

                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Sub Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={id}
                        onChange={handleChange}
                      >

                        {subcategories.map((category) =>
                          <MenuItem value={category.id}>{category.category_name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Is Book Free?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="isFree"
                        value={free}
                        onChange={handleFreeChange}
                      >

                        <MenuItem value={false}>{"Paid"}</MenuItem>
                        <MenuItem value={true}>{"Free"}</MenuItem>
                      </Select>
                    </FormControl>
                    {free == false ?
<>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Sample Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="sample_type"
                          value={sample_type}
                          onChange={handleSampleBookChange}
                        >
                          <MenuItem value={"Extract"}>{"Extract from Book"}</MenuItem>
                          <MenuItem value={"URL"}>{"URL"}</MenuItem>
                          <MenuItem value={"File"}>{"File"}</MenuItem>

                        </Select>
                      </FormControl>
<InputLabel id="demo-simple-select-label">
                          Note : To upload chapters, Please select sample type as "File" rather then "Extract from Book".
                        </InputLabel>
</>
                      :
                      <></>
                    }


                  </GridItem>
                  {sample_type == "Extract" ?
                    <GridItem xs={12} sm={12} md={4}>

                      <CustomInput
                        type="number"
                        labelText="Extract pages"
                        id="extract_pages"
                        name="extract_pages"
                        control={control}
                        value={1}
                        formControlProps={{
                          fullWidth: true
                        }}
                        required={true}
                      />

                    </GridItem>
                    :
                    <></>
                  }
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Name of the Book"
                      id="book_name"
                      name="name"
                      control={control}
                      formControlProps={{
                        fullWidth: true
                      }}
                      required={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Name of the Author"
                      id="Author_name"
                      name="author"
                      control={control}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                  {sample_type == "URL" && free == false ?
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="URL of Smaple Book"
                        id="url"
                        name="sample_url"
                        control={control}
                        formControlProps={{
                          fullWidth: true
                        }}
                        required={true}
                      />
                    </GridItem>
                    :
                    <></>
                  }
                  {sample_type == "File" && free == false ?
                    <GridItem xs={12} sm={12} md={12}>
                      <InputLabel style={{ color: "#AAAAAA" }}>
                        Upload Sample Book
                      </InputLabel>
                      <FileInput
                        id="sample-book"
                        type="file"
                        control={control}
                        name="sample_book"
                        onChange={HandleSampleFileInput}
                        required={true}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    :
                    <></>
                  }


                </GridContainer>
                {free == false ?

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Book Original Price"
                        id="orig_price"
                        name="orig_price"
                        type="number"

                        control={control}
                        formControlProps={{
                          fullWidth: true
                        }}
                        required={true}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Book Discounted Price"
                        id="discounted_price"
                        name="discounted_price"
                        type="number"

                        control={control}
                        formControlProps={{
                          fullWidth: true
                        }}
                        required={true}
                      />
                    </GridItem>


                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Book Pages (Optional)"
                        id="pages"
                        name="pages"
                        type="number"

                        control={control}
                        formControlProps={{
                          fullWidth: true
                        }}
                        required={false}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Book Author"
                        id="author"
                        name="book_author"
                        type="text"

                        control={control}
                        formControlProps={{
                          fullWidth: true
                        }}
                        required={false}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Book Publisher"
                        id="publisher"
                        name="publisher"
                        type="text"

                        control={control}
                        formControlProps={{
                          fullWidth: true
                        }}
                        required={false}
                      />
                    </GridItem>
                  </GridContainer>

                  :
                  <></>
                }

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl style={{ marginTop: "25px", width: "100px" }} className={classes.formControl}>
                      <InputLabel id="select-lang">
                        Language
                      </InputLabel>
                      <Select
                        labelId="select-lang"
                        id="demo-simple-select-lang"
                        value={language}
                        onChange={handlelangChange}
                      >
                        {languages.map((language) =>
                          <MenuItem value={language}>{language}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText="Tags(provide comma seperated tags)"
                      id="tags"
                      name="tags"
                      required={false}
                      control={control}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl style={{}} className={classes.formControl}>
                      <InputLabel id="select-is-audible">
                        Is Audible
                      </InputLabel>
                      <Select
                        labelId="select"
                        id="demo-simple-select-audible"
                        value={is_audible}
                        onChange={handleaudibleChange}
                      >

                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>

                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText="Item Format"
                      id="format"
                      name="format"
                      type="text"
                      value="Ebook"
                      control={control}
                      formControlProps={{
                        fullWidth: true
                      }}
                      required={false}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Enter the Description of the book"
                      id="Description"
                      name="desc"
                      control={control}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {errorLoadingSuggestions ? (<GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA" }}>
                      Upload Poster for the Book
                    </InputLabel>
                    <FileInput
                      id="image-file"
                      type="file"
                      control={control}
                      name="ImageFile"
                      onChange={HandleFileInput}

                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <FormHelperText>Poster size should be less than 2 mb</FormHelperText>
                  </GridItem>
                </GridContainer>) : uploadPosterOption ? (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <InputLabel style={{ color: "#AAAAAA" }}>
                          Upload Poster for the Book
                        </InputLabel>
                        <FileInput
                          id="image-file"
                          type="file"
                          control={control}
                          name="ImageFile"
                          onChange={HandleFileInput}

                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                        <FormHelperText>Poster size should be less than 2 mb</FormHelperText>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className="text-center mt-5 mb-3">
                          <button
                            className="btn btn-warning btn-sm ml-2"
                            onClick={(e) => {
                              setuploadPosterOption(false);
                              setPoster(null)
                            }}
                            type="button"
                          >
                            Show Suggestions Instead
                          </button>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </>
                ) : (
                  <>
                    <br />
                    {imageContent()}
                  </>
                )}
              </CardBody>
              <CardFooter>
                <Button disabled={disable} type="submit" color="rose">
                  Next
                </Button>
                {loader}
                {error.message && (
                  <Typography color="textSecondary" variant="subtitle2" gutterBottom>
                    {error.message}
                  </Typography>
                )}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

AddBook.propTypes = {
  auth: propTypes.object.isRequired,
  GetSubCategories: propTypes.func.isRequired,
  generatePosterUsingUserBackGround: propTypes.func.isRequired,
  addPoster: propTypes.func.isRequired,
  generatePosterImage: propTypes.func.isRequired,
  PostBook: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  poster: state.poster,
  items: state.items,
});

export default connect(
  mapStateToProps,
  {
    addPoster,
    generatePosterImage,
    GetAllCategories,
    GetAllSubCategories,
    PostBook,
    clearMybooks,
    clearCategories,
    clearSubcategories,
    clearSubbooks,
  }
)(AddBook);