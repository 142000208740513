import axios from "axios";
import { GET_ERRORS, GET_ALL_BOOKS, GET_ARCHIVES } from "./types";

import Keys from "../config/key.js";
axios.defaults.baseURL = Keys.base_url;

// UserPremiumArchive
export const getUserPremiumArchive = (LastEvaluatedKey) => (dispatch) => {
  axios
    .post("/api/user-premium-archive", { LastEvaluatedKey })
    .then((res) => {
      dispatch({
        type: GET_ARCHIVES,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      }
    });
};

//UserBookArhive
export const getUserBookArchive = (LastEvaluatedKey) => (dispatch) => {
  axios
    .post("/api/user-book-archive", { LastEvaluatedKey })
    .then((res) => {
      dispatch({
        type: GET_ARCHIVES,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      }
    });
};

//UserBook
export const getUserBooks = (LastEvaluatedKey) => (dispatch) => {
  axios
    .post("/api/user-books", { LastEvaluatedKey })
    .then((res) => {
      dispatch({
        type: GET_ALL_BOOKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      }
    });
};

//UserPremiumBook
export const getUserPremiumBook = (LastEvaluatedKey) => (dispatch) => {
  axios
    .post("/api/user-premium-books", { LastEvaluatedKey })
    .then((res) => {
      dispatch({
        type: GET_ALL_BOOKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      }
    });
};

//getArchiveMobileBooks
export const getArchiveMobileBooks = (LastEvaluatedKey) => (dispatch) => {
  axios
    .post("/api/mobile-book-archive", { LastEvaluatedKey })
    .then((res) => {
      dispatch({
        type: GET_ARCHIVES,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      }
    });
};

//getMobileBooks
export const getMobileBooks = (LastEvaluatedKey) => (dispatch) => {
  axios
    .post("/api/mobile-user-books", { LastEvaluatedKey })
    .then((res) => {
      dispatch({
        type: GET_ALL_BOOKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      }
    });
};
