import {MONTHLY_FILES_COUNT,PREMIUM_MONTHLY_FILES_COUNT} from "../actions/types"
const initialState ={
  data: {},
  premiumdata:{}
}

export default function(state = initialState , action){
    switch(action.type) {
      case MONTHLY_FILES_COUNT:
      return {
           ...state,
           data : action.payload,
      };
      case PREMIUM_MONTHLY_FILES_COUNT:
      return {
            ...state,
           premiumdata : action.payload,
      };
    default:
      return state;
  }
}