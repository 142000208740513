import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/test.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm, ErrorMessage, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { registerUser, changePassword } from "../../actions/authAction";
import {
  clearMybooks,
  clearCategories,
  clearSubcategories,
  clearSubbooks,
} from "../../actions/Books.js";
import propTypes from "prop-types";
import { Typography } from "@material-ui/core";
import FileInput from "components/CustomInput/CustomInput.js";


import Keys from "../../config/key.js";
const axios = require("axios");
axios.defaults.baseURL = Keys.base_url;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },

  form: {},
};

const useStyles = makeStyles(styles);

const UserAccount = (props) => {
  const [error, setError] = React.useState("");
  const [check, setCheck] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [cover, setCover] = React.useState(null);

  const { handleSubmit, errors, control } = useForm();

  const {
    handleSubmit: handleSubmit2,
    errors: errors2,
    control: control2,
  } = useForm({
    mode: "onBlur",
  });
  useEffect(() => {
    if (!props.auth.isAdmin) {
      props.history.push("/admin/Dashboard");
    }
    //Clear states of paginations
    props.clearCategories();
    props.clearMybooks();
    props.clearSubbooks();
    props.clearSubcategories();
  }, [props.auth]);

  useEffect(() => {
    setError(props.errors);
  }, [props.errors]);

  const onChangePasswordSubmit = (item) => {
    props.changePassword(item, props.history);
  };

  const HandleFileInput = (event) => {
    event.preventDefault();
    setImage(event.target.files[0]);
  };

  const HandleFileInputCover = (event) => {
    event.preventDefault();
    setCover(event.target.files[0]);
  };

  const onSubmit = async (item) => {
    item["isExternalCreator"] = check;
    item["image"] = "";
    item["cover"] = "";
    let formData = new FormData();
    formData.append("image", image);
    formData.append("cover", cover);
    formData.append("username", item.username);
    if (image !== null || cover !== null) {
      await axios
        .post("/api/user/profile-pic", formData)
        .then((res) => {
          item["image"] = res.data.image;
          item["cover"] = res.data.cover;
          console.log(item);
          props.registerUser(item, props.history);
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      console.log(item);
      props.registerUser(item, props.history);
    }
  };

  const optionalForm = () => {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="website"
              id="website"
              name="website"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="youtube"
              id="youtube"
              name="youtube"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="instagram"
              id="instagram"
              name="instagram"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="twitter"
              id="twitter"
              name="twitter"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="facebook"
              id="facebook"
              name="facebook"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="magtappShorts"
              id="magtappShorts"
              name="magtappShorts"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="googleMapsLink"
              id="googleMapsLink"
              name="googleMapsLink"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="locationName"
              id="locationName"
              name="locationName"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="description"
              id="description"
              name="description"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
            <InputLabel style={{ color: "#AAAAAA" }}>
              Upload Profile Picture
            </InputLabel>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} style={{ marginTop: "-40px" }}>
            <FileInput
              id="image-file"
              type="file"
              required={false}
              name="ImageFile"
              onChange={HandleFileInput}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
            <InputLabel style={{ color: "#AAAAAA" }}>
              Upload Cover Picture
            </InputLabel>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} style={{ marginTop: "-40px" }}>
            <FileInput
              id="cover-image-file"
              type="file"
              required={false}
              name="CoverImageFile"
              onChange={HandleFileInputCover}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
        </GridContainer>
      </>
    );
  };

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={7}>
          <form
            key={1}
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>Add User</h2>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Name"
                      id="name"
                      name="name"
                      control={control}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Username"
                      name="username"
                      control={control}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email address"
                      id="email-address"
                      name="email"
                      control={control}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Password"
                      id="password"
                      name="password"
                      type="password"
                      control={control}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="form-check" style={{ marginTop: "15px" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="featured-checkbox"
                        onChange={(e) => setCheck(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        style={{ color: "black" }}
                        htmlFor="defaultCheck1"
                      >
                        Is External Creator
                      </label>
                    </div>
                  </GridItem>
                </GridContainer>
                {check && optionalForm()}
              </CardBody>
              <CardFooter>
                <Button type="submit" color="primary">
                  ADD
                </Button>
                {error.message && (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    gutterBottom
                  >
                    {error.message}
                  </Typography>
                )}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <form
            key={2}
            className={classes.form}
            onSubmit={handleSubmit2(onChangePasswordSubmit)}
          >
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>Change Password</h2>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Username"
                      id="username"
                      name="username"
                      control={control2}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Old Password"
                      id="password"
                      name="oldpassword"
                      control={control2}
                      type="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="New Password"
                      name="newpassword"
                      control={control2}
                      type="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="submit" color="primary">
                  Update
                </Button>
                {error.changepassword && (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    gutterBottom
                  >
                    {error.changepassword}
                  </Typography>
                )}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <div style={{ textAlign: "center", marginTop: "15px" }}>
        <Button
          type="submit"
          color="primary"
          onClick={(e) => {
            window.location = `/admin/list-users`;
          }}
        >
          List Users
        </Button>
      </div>
    </div>
  );
};
UserAccount.propTypes = {
  registerUser: propTypes.func.isRequired,
  changePassword: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  {
    registerUser,
    changePassword,
    clearMybooks,
    clearCategories,
    clearSubcategories,
    clearSubbooks,
  }
)(UserAccount);
