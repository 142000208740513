import {GET_SEARCHES} from "../actions/types"

const initialState ={
   Books:[],
   Count: -1,
   Time: 0,
   Status: 200,
   Query: ""

}

export default function(state = initialState , action){
  console.log(action.payloadCategories)
    switch(action.type) {
        case GET_SEARCHES:
      return {
       Books : action.payload,
       Count: action.payload !== undefined ? action.payload.length : 0 ,
       Categories: action.payloadCategories,
       CategoriesCount: action.payloadCategories !== undefined ? action.payloadCategories.length : 0
      }
    default:
      return state;
  }
}