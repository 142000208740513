import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Download from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import { NavLink } from "react-router-dom";
import Eye from '@material-ui/icons/Visibility';
import {Modal} from "react-bootstrap"
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ShareIcon from '@material-ui/icons/Share';

const keys = require('../../config/key')
const crypto = require('crypto'); 
const algorithm = 'aes-128-cbc'; 
const key = keys.cryptoKey; 
const iv = keys.IV; 
const useStyles = makeStyles({
  root: {
    minWidth: 475
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

export default function CardBookDescription(props) {
  
  function decrypt(text) {

    try {
      const decipher = crypto.createDecipheriv(algorithm, key, iv);
      return decipher.update(text, 'base64', 'utf8') + decipher.final('utf8');
    }
    catch (e) {
      const old_decipher = crypto.createDecipheriv(algorithm, "fD439hHrxTjwz7lU", "wRK3M444JTl77A4b");
      return old_decipher.update(text, 'base64', 'utf8') + old_decipher.final('utf8');
    }

  }

  const classes = useStyles();
  const [book_password , setBookPassword] = React.useState('')
  const [show , setShow] = React.useState(false)
  const { Title, Description, Author, id ,book_name ,type , tags ,lang ,password ,isAdmin,...rest } = props;
  const link = "/admin/ViewBook/"+ type + "/" + id + "/edit";
  let booktags = ["No Tag for these book"]
  if(tags){
    booktags = tags
  }
 let language = "No Language specified"
  if(lang){
    language = lang
  }
  const showPassword = (password)=>{
    setShow(true)
    if(isAdmin==true){
    if(password==undefined){
      setBookPassword("No Password")
    }
    else{
      setBookPassword(decrypt(password))
    }
  }
  else{
    setBookPassword("Not Authenticated")
  } 
  }
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          <NavLink style={{textDecoration: 'none'}} to={link}><Edit /></NavLink><br />
          {Title}
        </Typography>
        <Typography variant="h5" component="h2">
          {Description}
          <br />
          <br />
        </Typography>
        {isAdmin==true? 
        <>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          <NavLink style={{textDecoration: 'none'}} onClick={()=>showPassword(password)}><Eye /></NavLink><br />
          {Title}
        </Typography>
        <Typography variant="h5" component="h2">
          View Password
          <br />
          <br />
        </Typography>
            </> :<></>}
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
              <Typography className={classes.pos} color="textSecondary">
              Author
            </Typography>
            <Typography variant="h5" component="h2">
              {Author}
            </Typography>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Typography className={classes.pos} color="textSecondary">
                   Language
            </Typography>
            <Typography variant="h5" component="h2">
                 {language}
            </Typography>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
               <Typography className={classes.pos} color="textSecondary">
                Tags
              </Typography>
              <Typography variant="h5" component="h2">
                {booktags.map(tag => 
                    <li>{tag}</li>
                  )}
              </Typography>
            </GridItem> 
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{marginTop:"25px"}}>
                <div className="text-center">
                <span>{props.viewCount !== undefined ? props.viewCount : 0} &nbsp;<Eye/></span>&nbsp;&nbsp;&nbsp;
                <span>{props.purchaseCount !== undefined ? props.purchaseCount : 0} &nbsp;<AttachMoneyIcon/></span>&nbsp;&nbsp;&nbsp;
                <span>{props.downloadCount !== undefined ? props.downloadCount : 0} &nbsp;<CloudDownloadIcon/></span>&nbsp;&nbsp;&nbsp;
                <span>{props.shareCount !== undefined ? props.shareCount : 0} &nbsp;<ShareIcon/></span>&nbsp;&nbsp;&nbsp;
                </div>
          </GridItem>
        </GridContainer>
        {
          props.isRejected && (
            <>
              <br/>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: "25px" }}>
                  <div className="text-center" style={{color:"red",fontSize:"17px"}}>
                    <span>This Book is rejected. Reason is given below:</span>
                    <br/>
                    <span>{props.rejectMessage !== undefined ? props.rejectMessage : "No reason mentioned"} &nbsp;</span>
                  </div>
                </GridItem>
              </GridContainer>
            </>
          )
        }

      </CardContent>
      <Modal
      show={show} onHide={()=>setShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
      <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Password
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <p>{book_password}</p>
      </Modal.Body>
      <Modal.Footer>
      <Button onClick={()=>setShow(false)}>Close</Button>
      </Modal.Footer>
      </Modal>
    </Card>
  );
}
CardBookDescription.propTypes = {
  Title: PropTypes.string,
  Description: PropTypes.string,
  Author: PropTypes.string,
  id: PropTypes.string,
  book_name : PropTypes.string
};
