import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { TablePagination } from "@material-ui/core";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { GetPurchaseHistory_v2, GetPurchaseHistory } from "../../actions/Books";
import { CircularProgress } from "@material-ui/core";
import DateConverter from "DateConverter/dateconverter";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Button from "components/CustomButtons/Button.js";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  header: {
    minHeight: "150px",
    width: "100%",
    padding: "10px",
    marginBottom: "30px",
  },
  headertitle: {
    marginLeft: "10px",
  },
  table: {
    marginTop: "20px",
  },
  formControl: {
    minWidth: 120,
    marginBottom: "20px",
  },
  searchButton: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);

const Purchasehistory = (props) => {
  const classes = useStyles();
  const [arr, setArr] = React.useState([]);
  const [index, setIndex] = React.useState(-1);
  const [disabled, setNext] = React.useState(true);
  const [prevItems, setPrev] = React.useState([]);
  const [flag, setFlag] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(-1);
  const [loader, setLoader] = React.useState(null);

  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date(`${new Date().getMonth() + 1}-01-${new Date().getFullYear()}`));
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

  const [state, setState] = React.useState({
    columns: [
      { title: "User", field: "user_name" },
      { title: "Status", field: "payment_status" },
      { title: "Book", field: "item_name" },
      { title: "Purchased Price", field: "item_purchase_price" },
      { title: "Purchased On", field: "timestamp" },
    ],
  });

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  useEffect(() => {
    props.GetPurchaseHistory_v2({}, selectedStartDate, selectedEndDate);
    // props.GetPurchaseHistory({});
    setLoader(<CircularProgress color="secondary" />);
    setFlag(true);
  }, [props.auth]);

  //Function to listen to new paginated data
  useEffect(() => {
    if (flag) {
      console.log('gdhsgd')
      //Push the next key in array
      setIndex(index + 1);
      setArr([...arr, props.purchase.Key]);
      prevItems.push.apply(prevItems, props.purchase.Books);
      props.purchase.Books.map((data) => {
        data.timestamp = DateConverter(data.timestamp);
      });
      setLoader(null);
      setData(prevItems);
    }
  }, [props.purchase]);

  // Load more Button to store previous state data and query for next paginated data
  const loadmore = (page) => {
    console.log(`page = ${page}`);
    console.log(`index = ${index}`);
    if (page > index && arr[index]) {
      setLoader(<CircularProgress color="secondary" />);
      var lastIndexKey = arr[index];
      setPrev(data);
      // Pass evaluated key and state contained dates
      props.GetPurchaseHistory_v2(lastIndexKey, selectedStartDate, selectedEndDate);
      // props.GetPurchaseHistory(lastIndexKey);
    }
  };

  // Logic to disable load more button when no key is recieved
  useEffect(() => {
    console.log("Execute props.Key effect", props.purchase.Key)
    if (props.purchase.Key) {
      setNext(false);
    } else {
      setNext(true);
    }
  }, [props.purchase.Key]);

  const updateData = () => {
    props.GetPurchaseHistory_v2({}, selectedStartDate, selectedEndDate);

    setPrev([]);
    console.log(props.purchase, 'dsfsd called');

    props.purchase.Books.map((data) => {
      data.timestamp = DateConverter(data.timestamp);
    });

    setData(props.purchase.Books);

  }

  return (
    <div>
      {loader}

      
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="flex-start">

          <Grid item md={4} xs={6} lg={4} >
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Start Date"
              format="dd/MM/yyyy"
              value={selectedStartDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>

          <Grid item md={4} xs={6} lg={4} >
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="End Date"
              format="dd/MM/yyyy"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />

          </Grid>
        </Grid>

      </MuiPickersUtilsProvider>

      <Button type="submit" color="rose" onClick={updateData} styles={{ margin: 10 }}>Search</Button>

      <MaterialTable
        title="Purchase History"
        className={classes.table}
        columns={state.columns}
        data={data.map((x) => Object.assign({}, x))}
        options={{
          pageSize: 10,
          exportButton: {
            csv: false,
            pdf: true
          }
        }}
        localization={{
          toolbar: {
            exportCSVName: "Export some Excel format",
            exportPDFName: "Export as pdf!!"
          }
        }}
        icons={
          ({ LastPage: () => <div /> },
          {
            FirstPage: () => <div />,
          })
        }
        onChangePage={(page) => {
          console.log(page);
          setPage(page);
          loadmore(page);
        }}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
            />
          ),
        }}
      />
    </div>
  );
};
Purchasehistory.propTypes = {
  GetPurchaseHistory_v2: propTypes.func.isRequired,
  // GetPurchaseHistory: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  purchase: state.purchase,
});

export default connect(
  mapStateToProps,
  { GetPurchaseHistory, GetPurchaseHistory_v2 }
)(Purchasehistory);
