export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const GET_POSTER = 'GET_POSTER'
export const CLEAR_ERRORS ='CLEAR_ERROR'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_SUBCATEGORIES = 'GET_SUBCATEGORIES'
export const GET_ACTIVITIES  = 'GET_ACTIVITIES '
export const GET_USERS = 'GET_USERS'
export const GET_BOOKS = 'GET_BOOKS'
export const CATEGORY_LOADING = 'CATEGORY_LOADING';
export const GET_FILE = 'GET_FILE';
export const GET_ALL_BOOKS = 'GET_ALL_BOOKS';
export const GET_MY_BOOKS = 'GET_MY_BOOKS';
export const GET_SOLUTION = 'GET_SOLUTION';
export const GET_CHAPTER = 'GET_CHAPTER';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_SUBCATEGORY = 'GET_SUBCATEGORY';
export const GET_SUB = 'GET_SUB'
export const CLEAR_ITEMS = 'CLEAR_ITEMS';
export const STATS = 'STATS';
export const SUB_BOOKS = 'SUB_BOOKS';
export const SUB_CATEGORIES = 'SUB_CATEGORIES'
export const MY_BOOKS = 'MY_BOOKS'
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES'
export const CLEAR_MYBOOKS = 'CLEAR_MYBOOKS'
export const CLEAR_SUBBOOKS = 'CLEAR_SUBBOOKS'
export const CLEAR_SUBCATEGORIES = 'CLEAR_SUBCATEGORIES'
export const CATEGORIES = 'CATEGORIES'
export const GET_ARCHIVES= 'GET_ARCHIVES'
export const GET_PURCHASE_HISTORY = "GET_PURCHASE_HISTORY"
export const GET_BOOK_REPORTS = "GET_BOOK_REPORTS"
export const GET_ARCHIVE_BOOK_REPORTS = "GET_ARCHIVE_BOOK_REPORTS"
export const GET_SEARCHES = 'GET_SEARCHES'
export const SET_LOADER = 'SET_LOADER'
export const REMOVE_LOADER = 'REMOVE_LOADER'
export const FINISH_LOADER = "FINISH_LOADR"
export const BOOK_COUNT = " BOOK_COUNT"
export const MONTHLY_FILES_COUNT = " MONTHLY_FILES_COUNT"
export const USER_UPLOADS = "USER_UPLOADS";
export const USER_MONTHLY_BOOK_UPLOADS = "USER_MONTHLY_BOOK_UPLOADS"
export const USER_PREMIUM_MONTHLY_BOOK_UPLOADS = "USER_PREMIUM_MONTHLY_BOOK_UPLOADS"
export const USER_WEEKLY_BOOK_UPLOADS = "USER_WEEKLY_BOOK_UPLOADS"
export const USER_PREMIUM_WEEKLY_BOOK_UPLOADS = "USER_PREMIUM_WEEKLY_BOOK_UPLOADS"
export const USER_MONTHLY_FILE_UPLOADS = "USER_MONTHLY_FILE_UPLOADS"
export const USER_WEEKLY_FILE_UPLOADS = " USER_WEEKLY_FILE_UPLOADS"
export const USER_PREMIUM_UPLOADS = " USER_PREMIUM_UPLOADS"
export const PREMIUM_MONTHLY_FILES_COUNT = "PREMIUM_MONTHLY_FILES_COUNT"
export const USER_PREMIUM_MONTHLY_FILE_UPLOADS = "USER_PREMIUM_MONTHLY_FILE_UPLOADS"
export const USER_PREMIUM_WEEKLY_FILE_UPLOADS = "USER_PREMIUM_WEEKLY_FILE_UPLOADS"
export const USER_MONTHLY_DOWNLOAD = "USER_MONTHLY_DOWNLOAD"
export const USER_WEEKLY_DOWNLOAD = "USER_WEEKLY_DOWNLOAD"
export const USER_MONTHLY_PURCHASED = "USER_MONTHLY_PURCHASED"
export const USER_WEEKLY_PURCHASED = "USER_WEEKLY_PURCHASED"
export const GET_ALL_PURCHASE_DETAILS = 'GET_ALL_PURCHASE_DETAILS'
export const CLEAR_PURCHASES = 'CLEAR_PURCHASES'
export const GET_PUBLISHER_ANALYTICS_COUNT = 'GET_PUBLISHER_ANALYTICS_COUNT'
export const GET_ALL_PUBLISHER_DETAILS = 'GET_ALL_PUBLISHER_DETAILS'

