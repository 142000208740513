const DateConverter = (timestamp) => {
    let date_ob = new Date(timestamp);
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getUTCFullYear();
    let hours = date_ob.getHours();
    let minutes = date_ob.getMinutes()
    let seconds = date_ob.getSeconds();
    return  date + "/" + month + "/" + year + " " + hours + ":" + minutes ;
   }

export default DateConverter;