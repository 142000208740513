var env = {
  IV: "ThisIsTheIvToUse",
  cryptoKey: "Zq3t6w9z$C&F)J@N",
  passwordDeleteUser: "password",
  passwordDeleteCategory: "password",
  passwordDeleteSubCategory: "password",
  base_url: 'https://book-cms-api.magtapp.in/'
};

module.exports = env;
