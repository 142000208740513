import { GET_PURCHASE_HISTORY, GET_ALL_PURCHASE_DETAILS, CLEAR_PURCHASES } from "../actions/types";

const initialState = {
  Books: [],
  Key: [],
  Count: [],
  Purchases: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PURCHASE_HISTORY:
      return {
        Books: action.payload.Items,
        Key: action.payload.LastEvaluatedKey,
        Count: action.payload.Count,
      };
    case GET_ALL_PURCHASE_DETAILS:
      return {
        Perchases: action.payload,
      };
    case CLEAR_PURCHASES:
      console.log('CLEAR_PURCHASES called')
      return {
        ...state,
        Purchases: []
      };
    default:
      return state;
  }
}
