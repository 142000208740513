import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardBooklg from "components/Card/CardBooklg.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBookDescription from "components/Card/CardBookDescription";
import List from "components/Lists/lists";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import { ViewBookPage, SwitchArchive } from "../../actions/Books.js"
import propTypes from "prop-types";
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from "@material-ui/icons/Delete";
import CountIcon from "@material-ui/icons/AvTimer";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Modal } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";

import Keys from "../../config/key.js";
axios.defaults.baseURL = Keys.base_url;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    display: "inline"
  },
  IconButton: {
    float: "right",
    display: "inline",
    marginTop: "20px",
    marginRight: "10px",

  },
  ArchiveButton: {
    float: "right",
    display: "inline",
    marginTop: "15px",
    marginRight: "10px",

  }
};

const useStyles = makeStyles(styles);

const BookPage = (props) => {
  const [books, setBooks] = React.useState([]);
  const [bookName, setBookName] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [chapters, setChapters] = React.useState([])
  const [solutions, setSolutions] = React.useState([])
  const [bookloading, setBookLoading] = React.useState(true)
  const [chapterloading, setChapterLoading] = React.useState(true)
  const [solutionloading, setSolutionLoading] = React.useState(true)
  const [book_url, setIsBook] = React.useState(false);
  const [cindex, setChapterIndex] = React.useState(0);
  const [sindex, setSolutionIndex] = React.useState(0);
  const [loader, setLoader] = React.useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [typeValue, setTypeValue] = useState('');
  const [error, setError] = useState('');
  const [modalError, setModalError] = useState('');
  const [modalIsOpenReasonReject, setmodalIsOpenReasonReject] = useState(false);
  const [reasonRejectValue, setReasonRejectValue] = useState('');

  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const cookies = new Cookies();
  useEffect(() => {
    axios
      .get(`/api/getChild/Chapter/${props.match.params.id}`)
      .then(res => {
        // setChapters(res.data);
        setChapters(res.data.sort((a, b) => {
          return parseInt(a.index) - parseInt(b.index)
        }))

        setChapterLoading(false);
      })
      .catch(err => {
        console.log(err);
      });

  }, [props.auth])

  useEffect(() => {
    axios
      .get(`/api/getChild/Solution/${props.match.params.id}`)
      .then(res => {
        setSolutions(res.data.sort((a, b) => {
          return parseInt(a.index) - parseInt(b.index)
        }));
        setSolutionLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, [props.auth])


  const switchToArchive = () => {
    var data = {};
    books.map((book) => {
      data = book;
    });

    if (data['ugc-timestamp'] !== undefined) {
      setReasonRejectValue("")
      setmodalIsOpenReasonReject(true)
    } else {
      setLoader(
        <CircularProgress
          visibility="hidden"
          style={{ float: "right" }}
          color="secondary"
        />
      );
      axios
        .put("/api/archiveSwitch", { data })
        .then((res) => {
          if (!res.data.type) {
            setError(res.data.message);
          } else {
            props.history.push(`/admin/ViewBook/${res.data.type}/${data.id}`);
            props.history.go();
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  };

  const rejectBook = async () => {
    setLoader(
      <CircularProgress
        visibility="hidden"
        style={{ float: "right" }}
        color="secondary"
      />
    );
    setmodalIsOpenReasonReject(false)
    var data = {};
    books.map((book) => {
      data = book;
    });
    axios
      .put("/api/archiveSwitch", { data, bookStatusMessage: reasonRejectValue })
      .then((res) => {
        setReasonRejectValue("")
        if (!res.data.type) {
          setError(res.data.message);
        } else {
          props.history.push(`/admin/ViewBook/${res.data.type}/${data.id}`);
          props.history.go();
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }

  const addbook = () => {
    let name;
    books.map((book) => {
      name = book.book_name.toString().replace("/", "-");
    })
    props.history.push("/admin/ViewBook/" + props.match.params.type + "/" + props.match.params.id + "/UploadBook/" + name)
  }



  const addchapter = () => {
    var largest = 0
    if (chapters.length === 1) {
      largest = parseInt(chapters[0].index);
    } else {
      chapters.sort(function (a, b) {
        largest = parseInt(a.index)
        largest = parseInt(a.index) > parseInt(b.index) ? parseInt(a.index) : parseInt(b.index);
      });
    }
    largest = parseInt(largest + 1);
    props.history.push("/admin/ViewBook/" + props.match.params.type + "/" + props.match.params.id + "/UploadChapter/" + largest)
  }






  const deleteBook = (type) => {

    //console.log('type',type)
    //console.log('book delete called', books)
    let name;
    let is_book_free;

    books.map((book) => {
      name = book.book_name
      is_book_free = book.is_book_free
    })

    setLoader(
      <CircularProgress visibility="hidden" style={{ float: "right" }} color="secondary" />
    )

    console.log('props.match.params', props.match.params)

    if (props.match.params.type === "A_Book") {
      axios
        .delete(`/api/deleteBook/${props.match.params.id}/${name}/A_Book/${type}`)
        .then((res) => {
          window.location.href = `/admin/${is_book_free ? 'Archivetable' : 'PremiumArchivetable'}`
          setLoader(null);
        })
        .catch((err) => {
          console.log(err);
          alert("Error occured while deleting this book");
          setLoader(null);
        });
    } else {
      setLoader(null);
      setOpenSnackBar(true)
    }
  }




  const addsolution = () => {
    var largest = 0
    if (solutions.length === 1) {
      largest = parseInt(solutions[0].index);
    } else {
      solutions.sort(function (a, b) {
        largest = parseInt(a.index)
        largest = parseInt(a.index) > parseInt(b.index) ? parseInt(a.index) : parseInt(b.index);
      });
    }
    largest = parseInt(largest + 1);
    props.history.push("/admin/ViewBook/" + props.match.params.type + "/" + props.match.params.id + "/UploadSolution/" + largest)
  }

  const BulkChapter = () => {
    let name;
    books.map((book) => {
      name = book.book_name.toString().replace("/", "-");
    })
    props.history.push("/admin/ViewBook/" + props.match.params.type + "/" + props.match.params.id + "/Bulkchapters/" + name)
  }

  const BulkSolution = () => {
    let name;
    books.map((book) => {
      name = book.book_name.toString().replace("/", "-");
    })
    props.history.push("/admin/ViewBook/" + props.match.params.type + "/" + props.match.params.id + "/Bulksolutions/" + name)
  }

  const setModalIsOpenToTrue = (data) => {
    console.log(data)
    setTypeValue(data)
    setModalValue('')
    setModalIsOpen(true)
  }
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }

  useEffect(() => {
    props.ViewBookPage(props.match.params.id, props.match.params.type, setBookLoading)
  }, [props.auth])

  useEffect(() => {
    //  console.log(props.items.Books[0].is_book_free)
    setBooks(props.items.Books);
    //cookies.set('is_book_free', props.items.Books[0].is_book_free);
    console.log(props.items.Books);

    console.log(book_url)
  }, [props.items.Books])


  useEffect(() => {
    setLoader(null)
  }, [props.errors])

  const valueChange = (event) => {
    let val = event.target.value;
    setModalValue(val);

  }

  const updatechapSol = () => {
    if (modalValue === '') {
      setModalError("Please fill value!")
    }
    else {
      setModalError("")
      axios.post('/api/update/chapSolu', { Ctype: typeValue, bookId: props.match.params.id, bookType: props.match.params.type, count: modalValue })
        .then(function (response) {
          // handle success
          console.log(response);
          window.location.reload()
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  let chapterlist;
  if (chapterloading) {
    chapterlist = <CardBody>
      <LinearProgress color="secondary" />
    </CardBody>



  } else if (chapters.length > 0) {
    chapterlist = <CardBody>
      {chapters.map((chapter) =>
        <List
          label={chapter.chapter_name}
          index={chapter.index}
          bookid={props.match.params.id}
          type="Chapter"
          Btype={props.match.params.type}
          id={chapter.id}
          DownloadLink={chapter.url}
          history={props.history}
        />
      )}

    </CardBody>

  } else {
    chapterlist = <CardBody>
      <h4>No Chapters</h4>
    </CardBody>

  }

  let solutionlist;
  if (solutionloading) {
    solutionlist = <CardBody>
      <LinearProgress color="secondary" />
    </CardBody>

  } else if (solutions.length > 0) {
    solutionlist = <CardBody>
      {solutions.map((solution) =>
        <List
          label={solution.chapter_name}
          id={solution.id}
          bookid={props.match.params.id}
          index={solution.index}
          type="Solution"
          Btype={props.match.params.type}
          DownloadLink={solution.url}
          history={props.history}
        />)}
    </CardBody>


  } else {
    solutionlist = <CardBody>
      <h4>No Solutions</h4>
    </CardBody>

  }

  let booklist;
  let del;
  if (bookloading) {
    booklist = <CardBody>
      <LinearProgress color="secondary" />
    </CardBody>


  } else if (books.length > 0) {
    booklist = <CardBody>
      {books.map((book) =>
        <List
          label={book.book_name}
          id={book.id}
          bookid={props.match.params.id}
          deleteBook={deleteBook.bind(this,'partial')}
          type={book.type}
          Btype={book.type}
          DownloadLink={book.url}
          history={props.history}
        />
      )}
    </CardBody>

  } else {
    booklist = <CardBody>
    </CardBody>


  }

  const classes = useStyles();
  if (loader) {
    del = loader;

  } else {
    del = <DeleteIcon onClick={deleteBook.bind(this,'full')} className={classes.IconButton} />
  }


  return (
    <>
      <p style={{ color: "red", textAlign: "center" }}>{error}</p>
      {books.length > 0 ? (books.map((book) =>
        <GridContainer>

          <Card>
            <CardHeader color="primary">
              <h1 className={classes.cardTitleWhite}>{book.book_name}</h1>
              {del}
              {
                props.match.params.type === "Book" ? book['ugc-timestamp'] !== undefined ? (
                  <Button
                    className={classes.ArchiveButton}
                    color="danger"
                    onClick={switchToArchive}
                  >
                    Reject
                  </Button>
                ) : (
                  <Button
                    className={classes.ArchiveButton}
                    color="warning"
                    onClick={switchToArchive}
                  >
                    Archive
                  </Button>
                ) : book['pending-ugc-timestamp'] !== undefined ? (
                  <Button
                    className={classes.ArchiveButton}
                    color="success"
                    onClick={switchToArchive}
                  >
                    Approve
                  </Button>
                ) : (
                  <Button
                    className={classes.ArchiveButton}
                    color="warning"
                    onClick={switchToArchive}
                  >
                    Publish
                  </Button>
                )
              }
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CardBooklg image={book.poster} title={book.book_name} id={book.id} />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CardBookDescription
                    Title="Description"
                    Description={book.description}
                    Author={book.author}
                    id={book.id}
                    type={book.type}
                    book_name={book.book_name}
                    lang={book.lang}
                    tags={book.tags}
                    password={book.password}
                    isAdmin={props.auth.isAdmin}
                    viewCount={book.viewCount}
                    purchaseCount={book.purchaseCount}
                    downloadCount={book.downloadCount}
                    shareCount={book.shareCount}
                    isRejected={book.bookStatus === "REJECTED"}
                    rejectMessage={book.bookStatusMessage}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>


          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h1 className={classes.cardTitleWhite}>Download Complete Book</h1>
                  <AddIcon onClick={addbook} className={classes.IconButton} />
                </CardHeader>
                {booklist}
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h1 className={classes.cardTitleWhite}>Download Chapters ({book.chapters})</h1>
                  <AddIcon onClick={addchapter} className={classes.IconButton} />
                  <CloudUploadIcon onClick={BulkChapter} className={classes.IconButton} />
                  <CountIcon onClick={() => setModalIsOpenToTrue("chapters")} className={classes.IconButton} />
                  <h3 className={classes.cardCategoryWhite}>Chapter List</h3>
                  <br />
                </CardHeader>
                {chapterlist}
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h1 className={classes.cardTitleWhite}>Download Solutions  ({book.solutions})</h1>
                  <AddIcon onClick={addsolution} className={classes.IconButton} />
                  <CloudUploadIcon onClick={BulkSolution} className={classes.IconButton} />
                  <CountIcon onClick={() => setModalIsOpenToTrue("solutions")} className={classes.IconButton} />
                  <h3 className={classes.cardCategoryWhite}>Solution Lists</h3>
                </CardHeader>
                {solutionlist}
              </Card>
            </GridItem>
          </GridContainer>
        </GridContainer>
      )) : bookloading ? (
        <div className="text-center">
          <h3 style={{ color: "black" }}>Loading ...</h3>
        </div>
      ) : (
        <div className="text-center">
          <h3 style={{ color: "red" }}>Book is deleted</h3>
        </div>
      )}
      <Modal
        backdrop="static"
        keyboard={false}
        show={modalIsOpen} onHide={setModalIsOpenToFalse}>
        <Modal.Header closeButton>
          <Modal.Title>Updating {typeValue} count</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: "center", color: "red" }}> {modalError} </p>
          <input type="number" class="form-control" id="count" aria-label="Small" placeholder={`count of ${typeValue}`} aria-describedby="inputGroup-sizing-sm" value={modalValue} onChange={valueChange} />
          <br />
          <button class="btn btn-success btn-sm" id="update" onClick={updatechapSol} href="#" role="button" style={{ width: "100%" }}>Update Count</button>
        </Modal.Body>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Cannot Delete a book which is not archive. First Archive Book then delete.
        </Alert>
      </Snackbar>
      <Modal
        backdrop="static"
        keyboard={false}
        show={modalIsOpenReasonReject}
        onHide={() => {
          setReasonRejectValue("")
          setmodalIsOpenReasonReject(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Please Specify a reason to Reject this book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend"></div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <label>Enter Reason</label>

              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <input
                  type="text"
                  class="form-control"
                  value={reasonRejectValue}
                  onChange={(e) => setReasonRejectValue(e.target.value)}
                />
              </GridItem>
            </GridContainer>
          </div>
          <p id="lead" style={{ width: "100%" }}>
            <button
              class="btn btn-danger btn-sm"
              id="confirm"
              onClick={() => rejectBook()}
              href="#"
              role="button"
              style={{ width: "100%" }}
            >
              REJECT
            </button>
          </p>
        </Modal.Body>
      </Modal>

    </>
  );
}

BookPage.propTypes = {
  ViewSubcategories: propTypes.func.isRequired,
  SwitchArchive: propTypes.func.isRequired,
  id: propTypes.string,
  type: propTypes.string
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  items: state.items
});

export default connect(mapStateToProps, { ViewBookPage, SwitchArchive })(BookPage);
