import {USER_UPLOADS , USER_MONTHLY_BOOK_UPLOADS , USER_WEEKLY_BOOK_UPLOADS , USER_MONTHLY_FILE_UPLOADS ,USER_MONTHLY_DOWNLOAD,USER_WEEKLY_DOWNLOAD,  USER_WEEKLY_FILE_UPLOADS,USER_WEEKLY_PURCHASED,USER_MONTHLY_PURCHASED, USER_PREMIUM_UPLOADS, USER_PREMIUM_MONTHLY_BOOK_UPLOADS,USER_PREMIUM_WEEKLY_BOOK_UPLOADS,USER_PREMIUM_MONTHLY_FILE_UPLOADS,USER_PREMIUM_WEEKLY_FILE_UPLOADS,PREMIUM_MONTHLY_FILES_COUNT} from "../actions/types"
const initialState ={
  data: {},
  data2:{},
  monthlybookcounts: {},
  weeklybookcounts: {},
  premiummonthlybookcounts: {},
  premiumweeklybookcounts: {},
  monthlyfilecounts: {},
  weeklyfilecounts: {},
  premiummonthlyfilecounts:{},
  premiumweeklyfilecounts:{},
  monthly_download:{},
  weekly_purchased:{},
  weekly_download:{},
  monthly_purchased:{}
}

export default function(state = initialState , action){
    switch(action.type) {
      case USER_UPLOADS:
      return {
          ...state,
           data : action.payload,
      };
      case USER_PREMIUM_UPLOADS:
      return {
          ...state,
           data2 : action.payload,
      };
      case USER_MONTHLY_FILE_UPLOADS:
        return {
          ...state,
             monthlyfilecounts : action.payload,
        };
        case USER_PREMIUM_MONTHLY_FILE_UPLOADS:
        return {
          ...state,
             premiummonthlyfilecounts : action.payload,
        };
      case USER_MONTHLY_BOOK_UPLOADS:
        return {
          ...state,
             monthlybookcounts : action.payload,
        };
        case USER_PREMIUM_MONTHLY_BOOK_UPLOADS:
        return {
          ...state,
             premiummonthlybookcounts : action.payload,
        };
        case USER_WEEKLY_BOOK_UPLOADS:
        return {
          ...state,
             weeklybookcounts : action.payload,
        };

        case USER_PREMIUM_WEEKLY_BOOK_UPLOADS:
        return {
          ...state,
             premiumweeklybookcounts : action.payload,
        };

       
        case USER_WEEKLY_FILE_UPLOADS:
        return {
          ...state,
             weeklyfilecounts : action.payload,
        };
        case USER_PREMIUM_WEEKLY_FILE_UPLOADS:
        return {
          ...state,
             premiumweeklyfilecounts : action.payload,
        };
        case USER_MONTHLY_DOWNLOAD:
        return {
          ...state,
          monthly_download: action.payload
        };
        case USER_WEEKLY_DOWNLOAD:
        return {
          ...state,
          weekly_download: action.payload
        };
        case USER_MONTHLY_PURCHASED:
        return {
          ...state,
          monthly_purchased: action.payload
        };
        case USER_WEEKLY_PURCHASED:
        return {
          ...state,
          weekly_purchased: action.payload
        };
        
    default:
      return state;
  }
}