import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BookIcon from "@material-ui/icons/Book";
import Notifications from "@material-ui/icons/Notifications";
import CategoryIcon from "@material-ui/icons/Category";
import LocalLibrarySharpIcon from "@material-ui/icons/LocalLibrarySharp";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import UserDashboard from "views/UserDashboard/UserDashboard.js";
import AddCategory from "views/AddCategory/addCategory.js";
import AddBook from "views/AddBook/Step1.js";
import TableList from "views/TableList/TableList.js"
import PremiumTableList from "views/TableList/PrimiumTableList.js"
import UserActivity from "views/UserActivity/UserActivity.js";
import NewUserActivity from "views/UserActivity/newUserActiviy";
import ArchiveList from "views/Archive/archive"
import PremiumArchiveList from "views/Archive/PremiumArchive.js"
import Analytics from "views/Analytics/analytics"
import position_cat from "views/Position/category_position"
import position_subcat from "views/Position/subcategory_position"
import AssessmentIcon from '@material-ui/icons/Assessment';
import UserBookArchive from 'views/Archive/UserBookArchive'
import UserBook from 'views/TableList/UserBook'
import UserPremiumArchive from 'views/Archive/UserPremiumArchive'
import UserPremiumBook from 'views/TableList/UserPremiumBook'
import MobileArchiveBooks from 'views/Archive/MobileArchiveBooks'
import MobileBooks from 'views/TableList/MobileBooks'
import CategoryDashboard from "views/Categories/Categories.js";
import UserBookApproval from 'views/Approval/UserBookApproval'
import reports from 'views/BookReports/reports'
import archiveReports from 'views/BookReports/archiveReports'
import PublisherDashboard from 'views/Publisher/PublisherDashboard';
import Trending from "views/Trending/Trending";
import TopSelling from "views/TopSelling/TopSelling";
import NewReleases from "views/NewReleases/NewReleases"
import BannerUpload from "views/BannerUpload/BannerUpload";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/Analytics",
    name: "Analytics",
    icon: AssessmentIcon,
    component: Analytics,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "Manage User",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/UserDashboard",
    name: "My Books",
    icon: LocalLibrarySharpIcon,
    component: UserDashboard,
    layout: "/admin"
  },
  {
    dropDown:true,
    name:"Books",
    key:"book",
    items:[
      {
        path: "/table",
        name: "Book List",
        icon: "content_paste",
        component: TableList,
        layout: "/admin"
      },
      {
        path: "/Archivetable",
        name: "Archive Book List",
        icon: "content_paste",
        component: ArchiveList,
        layout: "/admin"
      },
      
      {
        path: "/Premiumtable",
        name: "Premium Book List",
        icon: "content_paste",
        component: PremiumTableList,
        layout: "/admin"
      },
      {
        path: "/PremiumArchivetable",
        name: "Premium Archive List",
        icon: "content_paste",
        component: PremiumArchiveList,
        layout: "/admin"
      },
    ]
  },

  {
    dropDown:true,
    name:"User Books",
    key:"user",
    items:[
      {
        path: "/book-list-user",
        name: "User Book",
        icon: "content_paste",
        component: UserBook,
        layout: "/admin"
      },
      // {
      //   path: "/archive-book-list-user",
      //   name: "User Archive",
      //   icon: "content_paste",
      //   component: UserBookArchive,
      //   layout: "/admin"
      // },
      {
        path: "/premium-book-list-user",
        name: "User Premium Books",
        icon: "content_paste",
        component: UserPremiumBook,
        layout: "/admin"
      },
      {
        path: "/premium-archive-book-list-user",
        name: "User Premium Archive",
        icon: "content_paste",
        component: UserPremiumArchive,
        layout: "/admin"
      },
    ]
  },

  {
    dropDown:true,
    name:"Mobile Books",
    key:"mobile",
    items:[
      {
        path: "/mobile-user-books",
        name: "Mobile Books",
        icon: "content_paste",
        component: MobileBooks,
        layout: "/admin"
      },
      {
        path: "/mobile-user-archive",
        name: "Mobile Archive",
        icon: "content_paste",
        component: MobileArchiveBooks,
        layout: "/admin"
      },
    ]
  },

  {
    dropDown:true,
    name:"Category",
    key:"category",
    items:[
      {
        path: "/add-category",
        name: "Add Category",
        icon: "content_paste",
        component: AddCategory,
        layout: "/admin"
      },
      {
        path: "/Position-Category",
        name: "Position Category",
        icon: "content_paste",
        component: position_cat,
        layout: "/admin"
      },
      {
        path: "/Position-SubCategory",  
        name: "Position Sub-Category",
        icon: "content_paste",
        component: position_subcat,
        layout: "/admin"
      },
    ]
  },

  {
    dropDown:true,
    name:"Moderation",
    key:"moderation",
    items:[
      {
        path: "/pending-approval-books",
        name: "Pending Approval",
        icon: "content_paste",
        component: UserBookApproval,
        layout: "/admin"
      },
      {
        path: "/report-books",
        name: "Book Reports",
        icon: "content_paste",
        component: reports,
        layout: "/admin"
      },
      {
        path: "/report-archive-books",
        name: "Archive Reports",
        icon: "content_paste",
        component: archiveReports,
        layout: "/admin"
      },
    ]
  },

  // {
  //   path: "/UserActivity",
  //   name: "User Activity",
  //   icon: Notifications,
  //   component: UserActivity,
  //   layout: "/admin"
  // },
  {
    path: "/NewUserActivity",
    name: "User Activity",
    icon: Notifications,
    component: NewUserActivity,
    layout: "/admin"
  },
  {
    path: "/Add-Book",
    name: "Add Book",
    icon: BookIcon,
    component: AddBook,
    layout: "/admin"
  },
  {
    path: "/Dashboard/55f8bc21-4286-449c-825f-de27e3475930",
    name: "User Read Content",
    icon: Dashboard,
    component: CategoryDashboard,
    layout: "/admin"
  },
  {
    path : "/publisher-analytics",
    name : "Publisher Analytics",
    icon : AssessmentIcon,
    component: PublisherDashboard,
    layout: "/admin"
  },
  {
    dropDown:true,
    name:"Explore",
    key:"explore",
    items:[
      {
        path: "/tranding",
        name: "Tranding",
        icon: "content_paste",
        component: Trending,
        layout: "/admin"
      },
      {
        path: "/top-selling",
        name: "Top Selling",
        icon: "content_paste",
        component: TopSelling,
        layout: "/admin"
      },
      {
        path: "/new-releases",  
        name: "New Releases",
        icon: "content_paste",
        component: NewReleases,
        layout: "/admin"
      },
      {
        path: "/bannerupload",
        name: "BannerUpload",
        icon: "content_paste",
        component: BannerUpload,
        layout: "/admin"
      },
    ]
  },
];

export default dashboardRoutes;
