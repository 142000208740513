import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { TablePagination } from '@material-ui/core';
// core components
import axios from 'axios';
import propTypes from "prop-types";
import SelectSearch from 'react-select-search';
import { connect } from "react-redux";
import DateConverter from "DateConverter/dateconverter"
import Autocomplete from 'react-autocomplete'
import {  GetTableActivities } from "../../actions/Books";
import { CircularProgress } from '@material-ui/core';
import { Button, Table, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 

import Keys from "../../config/key.js";
axios.defaults.baseURL = Keys.base_url;

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    },
    header: {
        minHeight: "150px",
        width: "100%",
        padding: "10px",
        marginBottom: "30px"
    },
    headertitle: {
        marginLeft: "10px"
    },
    table: {
        marginTop: "20px"
    },
    formControl: {
        minWidth: 120,
        marginBottom: "20px"
      },
      searchButton: {
        marginTop: "10px"
      }
  };



const useStyles = makeStyles(styles);

const  ArchiveBooks = (props) =>{


//Material ui Table for words
const classes = useStyles();
//States for User list Tables 
const  [arr , setArr] = React.useState([]);
const [index , setIndex] = React.useState(-1);
const [disabled , setNext ] = React.useState(true)
const [prevItems , setPrev] = React.useState([])
const [flag , setFlag] = React.useState(false)
const [data , setData] = React.useState([]);
const [page , setPage] = React.useState(-1);
const [loader , setLoader] = React.useState(null)
const [options , setOptions] = React.useState([])
const [value , setValue] = React.useState('')
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalIsOpen2, setModalIsOpen2] = useState(false);
const [category, setModalCotegory] = useState('');
const [categoryId, setModalCotegoryId] = useState('');
const [category1, setCategory1] = useState('');
const [category2, setCategory2] = useState('');
const [status, setStatus] = useState('');
const [err, setError] = useState('');
 useEffect( () => {
    var items = {}
    props. GetTableActivities(items)
     setLoader(
      <CircularProgress color="secondary" />
    )
    setFlag(true)
}, [props.auth])  
 

const setModalIsOpenToTrue =(id,name)=>{
 
  setModalCotegory(name) 
   setModalCotegoryId(id)
   setModalIsOpen(true) 
 
}
const setModalIsOpenToFalse =()=>{
 setModalIsOpen(false)
}
const setModalIsOpenToTrue2 =(id,name)=>{
 
  setModalCotegory(name) 
   setModalCotegoryId(id)
   setModalIsOpen2(true) 
 
}
const setModalIsOpenToFalse2 =()=>{
 setModalIsOpen2(false)
}


//Function to listen to new paginated data 
useEffect( () => {
axios.get('/api/getAllSubCategoriesData')
    .then(res => {
        console.log("data is: ")
        console.log(res.data)
    setLoader(null)
    setData(res.data);
    
    })
  
    
   }, [])  
  


 // Load more Button to store previous state data and query for next paginated data
  const loadmore = (page) =>{
     console.log(`page = ${page}`)
     console.log(`index = ${index}`);
     if(page > index && arr[index]){
      setLoader(
        <CircularProgress color="secondary" />
      )
      var lastIndexKey = arr[index];
      setPrev(data);
  // Pass evaluated key and state contained dates
      props.GetTableActivities(lastIndexKey) 
     }
    }
  
  
// Logic to disable load more button when no key is recieved
useEffect( () => {
  if(props.activities.Key){
    setNext(false)
  }else{
    setNext(true)
  }
   
 } ,[props.activities.Key])
 function pushTop(id){
  setLoader(
    <CircularProgress color="secondary" />
  )
  axios.post('/api/pushTop/sub',{id:id})
    .then(res => {
        
        setData(res.data);
     
      setLoader(null)
    })
 }

 function fix_position(category1,val) {
  document.getElementById("cat_"+value).disabled=true
  document.getElementById("cat_"+value).value=''
  setLoader(
    <CircularProgress color="secondary" />
  )
  
  
  axios.post('/api/fixpostion/sub',{category:category1,value:val})
    .then(res => {
        setData(res.data);
        
    
        setLoader(null)
    })
}
// function fix_position2(category1,category2) {
//   setLoader(
//     <CircularProgress color="secondary" />
//   )
//   {setModalIsOpen2(false)}
//   axios.post('/api/below/fixpostion/sub',{category1:category1,category2:category2})
//     .then(res => {
        
//         setData(res.data);
//         setCategory1('')
//         setCategory2('')
//       setLoader(null)
//     })
// }
// function setCategoryAB1(category1Id,status) {
//   setError("Please select 2nd Sub Category from the table to move "+status)
//   setCategory1(category1Id)
//   setStatus(status)
// }
// function setCategoryAB2(category2Id) {
//   if(category1==""){
//     setError("Please select 1st Sub Category to move")
//   }
//   else{
//   setError("")
//   setCategory2(category2Id)
//   if(status=="above"){
//     fix_position(category1,category2Id)
//   }
//   else if(status=="below"){
//     fix_position2(category1,category2Id)
//   }
//   }
  
// }
function updatePosition(id) {
  if(document.getElementById("cat_"+id).value==''){
    setError("Please give the correct Position")
  }
  else{
    setError('')
    fix_position(id,document.getElementById("cat_"+id).value)
  }
}
function editInput(id) {
  if(value!=''){
    document.getElementById("cat_"+value).disabled=true
    document.getElementById("cat_"+value).value=''
  }
  document.getElementById("cat_"+id).disabled=false
  setValue(id)
}
  return (
    <div>
      <p style={{textAlign:"center", color:"red"}}>{err}</p>
       {loader} 
       <Table striped bordered hover responsive>
  <thead>
    <tr>
      
      <th>Sub Category Name</th>
      <th>Position</th>
      <th>Edit Position</th>
      <th>Update Position</th>
      
    </tr>
  </thead>
  <tbody>
    { data.map(itemss =>
    <tr>
      <td>{itemss.category_name}</td>
      <td>
      <input
            
            type="number"
            value={itemss.position}
            readOnly/> </td>
   <td>
      <input
            id={`cat_`+itemss.id}
            type="number"
       
            disabled></input> <span style={{marginLeft:"10px"}}><a href="#" onClick={()=>editInput(itemss.id)}>Edit</a></span></td>
    <td><Button onClick={()=>updatePosition(itemss.id)}>Update</Button></td>
    </tr>
    )}
    </tbody>
    </Table>
    {/* <Modal
    backdrop="static"
    keyboard={false} 
   show={modalIsOpen} onHide={setModalIsOpenToFalse}>
<Modal.Header closeButton> 
      <Modal.Title>Select Sub-Category:</Modal.Title> 
    </Modal.Header> 
    <Modal.Body> 
    { data.map(itemss =>
    <>
      <div style={{textAlign:"center"}}>
        {itemss.category_name!=category?
        <div style={{marginTop:"10px"}}>
    <button  onClick={()=>fix_position(categoryId,itemss.id)}>{itemss.category_name}</button>
    </div>
       :<span></span> }
       
    </div>
  
    </>
    )}
    </Modal.Body> 
    

</Modal>
    

<Modal
    backdrop="static"
    keyboard={false} 
   show={modalIsOpen2} onHide={setModalIsOpenToFalse2}>
<Modal.Header closeButton> 
      <Modal.Title>Select Sub-Category:</Modal.Title> 
    </Modal.Header> 
    <Modal.Body> 
    { data.map(itemss =>
    <>
      <div style={{textAlign:"center"}}>
        {itemss.category_name!=category?
        <div style={{marginTop:"10px"}}>
    <button  onClick={()=>fix_position2(categoryId,itemss.id)}>{itemss.category_name}</button>
    </div>
       :<span></span> }
       
    </div>
  
    </>
    )}
    </Modal.Body> 
    

</Modal> */}
    </div>
  );
}
ArchiveBooks.propTypes ={
     GetTableActivities: propTypes.func.isRequired
};
const mapStateToProps =(state) =>({
  auth: state.auth,
  errors: state.errors,
  activities: state.activities
});

export default connect(mapStateToProps, { GetTableActivities})(ArchiveBooks);