/*eslint-disable*/
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const [openBook, setOpenBook] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openModeration, setOpenModeration] = useState(false);

  const handleClick = (key) => {
    if (key === "user") {
      setOpenUser(!openUser);
    } else if (key === "mobile") {
      setOpenMobile(!openMobile);
    } else if (key === "category") {
      setOpenCategory(!openCategory);
    } else if (key === "moderation") {
      setOpenModeration(!openModeration);
    } else {
      setOpenBook(!openBook);
    }
  };
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  function activeRouteFromDrop(routeNames) {
    let exists = false;
    for (let index = 0; index < routeNames.length; index++) {
      if (
        window.location.href.indexOf(
          routeNames[index].layout + routeNames[index].path
        ) > -1
      ) {
        exists = true;
        break;
      }
    }
    return exists;
  }

  const { color, logo, image, logoText, routes, history } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.dropDown) {
          var listItemClasses;

          listItemClasses = classNames({
            [" " + classes[color]]: activeRouteFromDrop(prop.items),
          });
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRouteFromDrop(prop.items),
          });
          return (
            <span key={key}>
              <ListItem
                button
                className={classes.itemLink + listItemClasses}
                onClick={() => handleClick(prop.key)}
                key={key}
              >
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                >
                  {prop.key === "book" || prop.key === "mobile" ? (
                    <ViewAgendaIcon
                      className={classNames(
                        classes.itemIcon,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  ) : (
                    <ViewListIcon
                      className={classNames(
                        classes.itemIcon,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}
                </Icon>
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ListItemText
                    primary={prop.name}
                    className={classNames(classes.itemText, whiteFontClasses, {
                      [classes.itemTextRTL]: props.rtlActive,
                    })}
                    disableTypography={true}
                  />
                  <Icon
                    // style={{ marginLeft: "-100px" }}
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.key === "user" ? (
                      !openUser ? (
                        <ArrowDropDownIcon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )
                    ) : prop.key === "mobile" ? (
                      !openMobile ? (
                        <ArrowDropDownIcon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )
                    ) : prop.key === "book" ? (
                      !openBook ? (
                        <ArrowDropDownIcon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )
                    ) : prop.key === "moderation" ? (
                      !openModeration ? (
                        <ArrowDropDownIcon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive,
                            }
                          )}
                        />
                      )
                    ) : !openCategory ? (
                      <ArrowDropDownIcon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    ) : (
                      <ArrowDropUpIcon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive,
                          }
                        )}
                      />
                    )}
                  </Icon>
                </span>
              </ListItem>
              <Collapse
                in={
                  prop.key === "user"
                    ? openUser
                    : prop.key === "mobile"
                    ? openMobile
                    : prop.key === "category"
                    ? openCategory
                    : prop.key === "moderation"
                    ? openModeration
                    : openBook
                }
                timeout="auto"
                unmountOnExit
              >
                {prop.items.map((item, key1) => {
                  const listItemClasses1 = classNames({
                    [" " + classes[color]]: activeRoute(
                      item.layout + item.path
                    ),
                  });

                  const whiteFontClasses1 = classNames({
                    [" " + classes.whiteFont]: activeRoute(
                      item.layout + item.path
                    ),
                  });
                  return (
                    <NavLink
                      to={item.layout + item.path}
                      className={activePro + classes.item}
                      activeClassName="active"
                      key={`${key}-${key1}`}
                    >
                      <ListItem
                        button
                        className={classes.itemLink + listItemClasses1}
                      >
                        {typeof item.icon === "string" ? (
                          <Icon
                            className={classNames(
                              classes.itemIcon,
                              whiteFontClasses1,
                              {
                                [classes.itemIconRTL]: props.rtlActive,
                              }
                            )}
                          >
                            {item.icon}
                          </Icon>
                        ) : (
                          <prop.icon
                            className={classNames(
                              classes.itemIcon,
                              whiteFontClasses1,
                              {
                                [classes.itemIconRTL]: props.rtlActive,
                              }
                            )}
                          />
                        )}
                        <ListItemText
                          primary={props.rtlActive ? item.rtlName : item.name}
                          className={classNames(
                            classes.itemText,
                            whiteFontClasses1,
                            {
                              [classes.itemTextRTL]: props.rtlActive,
                            }
                          )}
                          disableTypography={true}
                        />
                      </ListItem>
                    </NavLink>
                  );
                })}
              </Collapse>
            </span>
          );
        } else {
          var activePro = " ";
          var listItemClasses;
          if (prop.path === "/upgrade-to-pro") {
            activePro = classes.activePro + " ";
            listItemClasses = classNames({
              [" " + classes[color]]: true,
            });
          } else {
            listItemClasses = classNames({
              [" " + classes[color]]: activeRoute(prop.layout + prop.path),
            });
          }
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
          });
          return (
            <NavLink
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
        }
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="/admin/dashboard"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="persistent"
          anchor="right"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {<AdminNavbarLinks history={history} />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          anchor="left"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
