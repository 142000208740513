import { GET_PUBLISHER_ANALYTICS_COUNT, GET_ALL_PUBLISHER_DETAILS } from "../actions/types";

const initialState = {
  PublisherCounts: {},
  Publishers: []
};

export default function (state = initialState, action) {
  console.log(action)
  switch (action.type) {
    case GET_PUBLISHER_ANALYTICS_COUNT:
      return {
        PublisherCounts: action.payload
      };
    case GET_ALL_PUBLISHER_DETAILS:
      return {
        Publishers: action.payload
      };
    default:
      return state;
  }
}
