import Dashboard from "@material-ui/icons/Dashboard";
import BookIcon from "@material-ui/icons/Book";
import LocalLibrarySharpIcon from "@material-ui/icons/LocalLibrarySharp";
import UserDashboard from "views/UserDashboard/UserDashboard.js";
import AddBook from "views/AddBook/Step1.js";
import TableList from "views/TableList/TableList.js";
import PremiumTableList from "views/TableList/PrimiumTableList.js";
import ArchiveList from "views/Archive/archive";
import PremiumArchiveList from "views/Archive/PremiumArchive.js";
import UserAnalytics from "views/Analytics/UserAnalytics";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PurchaseHistory from "../views/PurchaseDetails/PurchaseHistory";

const UserdashboardRoutes = [
  {
    path: "/user-new-dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: UserAnalytics,
    layout: "/admin",
  },
  {
    path: "/UserDashboard",
    name: "My Books",
    icon: LocalLibrarySharpIcon,
    component: UserDashboard,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "My Book List",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/Archivetable",
    name: "My Archive Book List",
    icon: "content_paste",
    component: ArchiveList,
    layout: "/admin",
  },

  {
    path: "/Premiumtable",
    name: "My Premium Book List",
    icon: "content_paste",
    component: PremiumTableList,
    layout: "/admin",
  },
  {
    path: "/PremiumArchivetable",
    name: "My Premium Archive List",
    icon: "content_paste",
    component: PremiumArchiveList,
    layout: "/admin",
  },
  {
    path: "/Add-Book",
    name: "Add Book",
    icon: BookIcon,
    component: AddBook,
    layout: "/admin",
  },
  {
    path: "/purchase-history",
    name: "Purchase History",
    icon: MonetizationOnIcon,
    component: PurchaseHistory,
    layout: "/admin",
  },
];

export default UserdashboardRoutes;
