import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
// @material-ui/core
import BookCollection from "./BookCollection"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from '@material-ui/core/Button';
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem";
import { connect } from "react-redux";
import {ViewSubcategories, DeleteSubCategory} from "../../actions/Books.js"
import propTypes from "prop-types";
import LinearProgress from '@material-ui/core/LinearProgress';
import EditIcon from '@material-ui/icons/Edit';
import { NavLink } from "react-router-dom";
import CustomLink from "components/CustomLink/customlink.js"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/LinkTwoTone";
import {Modal} from 'react-bootstrap';

const keys = require("../../config/key")

const styles = {
  loading: {
    width: '100%',
    '& > * + *': {
     
    },
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    display: "inline"
  },
  IconButton:{
    float: "right",
    display: "inline",
    marginTop: "10px",
    marginRight: "10px"
    
  },
  backarrow: {
    marginLeft: '50%'
  }
};

const useStyles = makeStyles(styles);
const CategoryDashBoard = (props)=> {
  const classes = useStyles();
  const [ loading , setLoading] = React.useState(true)
  const [subcategories, setSubCategories] = React.useState([]);

  const  [arr , setArr] = React.useState([]);
  const [index , setIndex] = React.useState(-1);
  const [disabled , setNext ] = React.useState(true)
  const [prevItems , setPrev] = React.useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [data, setData] = useState([]);
  const [modalPassword,setModalPassword] = useState("")
  const [passwordError,setPasswordError] = useState("")

  var item ={}


 var item = {};

  useEffect( () => {
    let cat_id = props.match.params.cat_id
    if(cat_id === undefined){
      try{
        props.match.params.cat_id = window.location.href.split("/admin/Dashboard/")[1]
      }catch(err){
      }
    }
    props.ViewSubcategories(props.match.params.cat_id ,item )
  } ,[props.auth])

  const loadmore = (event)=>{
    console.log(arr);
    console.log(index);
    var lastIndexKey = arr[index];
    setPrev(subcategories);
    props.ViewSubcategories(props.match.params.cat_id , lastIndexKey  )
  }

  const setModalIsOpenToTrue =(data)=>{
    console.log(data)
    setData(data)
   setModalValue('')
   setModalIsOpen(true)
   setPasswordError("")
   setModalPassword("")
  }
  const setModalIsOpenToFalse =()=>{
    setModalIsOpen(false)
    setPasswordError("")
   setModalPassword("")
  }
  const valueChange =(event)=>{
    document.getElementById("lead").style.display="block"
      let val = event.target.value;
      setModalValue(val);
      if(val=="confirm"){
        document.getElementById("confirm").disabled = false;
      }
      else{
        document.getElementById("confirm").disabled = true;
      }
  }
  const removeCategory =(data)=>{
    document.getElementById("confirm").disabled = true;
    if(modalPassword === keys.passwordDeleteSubCategory){
      setPasswordError("")
      DeleteSubCategory(data)
     }else{
       setPasswordError("Password is wrong")
       document.getElementById("confirm").disabled = false
       setModalPassword("")
     }
  }
  
  const DeleteSubCategory = (item) =>{
    console.log(item)
    props.DeleteSubCategory(item , props.history)
  }

  
useEffect( () => {
 // Logic to disable buuton at last page
  if(!props.subcategories.Key){
    //disable the button next
    setNext(true);
  }else{
    setNext(false)
  }

  //Push the next key in array
setIndex(index+1)
setArr([ ...arr , props.subcategories.Key])
prevItems.push.apply(prevItems, props.subcategories.Subcategories);
setSubCategories(prevItems);
  setLoading(false);
}, [props.subcategories])



let Content;
if(loading) {
  Content =<GridContainer>
                  <GridItem xs={12} sm={4} md={12}>
                  <LinearProgress color="secondary" />
                  </GridItem>
                </GridContainer> 


} else if(subcategories && subcategories.length >0){
  Content = <GridContainer>
  {subcategories.map((category) => 
      <Card>
      <CardHeader color="primary">
       <NavLink to ={"/admin/dashboard/"+ props.match.params.cat_id +"/"+ category.id} >
        <h2 className={classes.cardTitleWhite}>{category.category_name}</h2>
        </NavLink>
        <a href="#" style={{color:"white"}}><DeleteIcon onClick={() => setModalIsOpenToTrue(category)} className ={classes.IconButton} /></a>
        < CustomLink id = {category.id} />
        {/* <a href="#" style={{color:"white"}}><LinkIcon onClick={()=> window.open(< CustomLink id = {category.id} />,'_blank')} className ={classes.IconButton} /></a> */}
      <h4 className={classes.cardCategoryWhite}>{category.category_Desc}</h4>
      </CardHeader>
       <BookCollection id={category.id} type= "Book"/>
    </Card>
   )}           <Card>
                 <CardFooter>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled = {disabled}
                      className={classes.button}
                      onClick = {loadmore}
                     >LOAD MORE</Button>
                    </CardFooter>
                    </Card>
            </GridContainer>

} else{
  Content = <GridContainer>
              <h3>No Books and Subcategories in the Category</h3>
            </GridContainer>
}



  return (
    <>
    <div>
      {Content}
    </div>
    <Modal
    backdrop="static"
    keyboard={false} 
   show={modalIsOpen} onHide={setModalIsOpenToFalse}>
<Modal.Header closeButton> 
      <Modal.Title>Are you absolutely sure?</Modal.Title> 
    </Modal.Header> 
    <Modal.Body> 
      <div class="alert alert-warning" role="alert">
      Unexpected bad things will happen if you don't read this!
      </div>
      This action cannot be undone. This will permanently delete all the courses and Sub Categories of this category <br/>
      <br/>
      Please type <strong><u>confirm</u></strong> and enter password to delete this category, Subcategories and its courses permanently.<br/><br/>
      <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend">
      
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <label>Confirm Delete</label>
      <input type="text" class="form-control" value={modalValue} onChange={valueChange}/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <label>Enter password</label>
      <input type="password" class="form-control" value={modalPassword} onChange={(e)=>{
        setModalPassword(e.target.value)
        setPasswordError("")
        }}/>
        </GridItem>
      </GridContainer>
  
      <p style={{fontSize:"10px",color:"red"}}>{passwordError.length > 0 ? passwordError : ""}</p>

      </div>
    <p id="lead" style={{width:"100%",display:"none"}}>
    <button class="btn btn-danger btn-sm" id="confirm" onClick={()=>removeCategory(data)} href="#" role="button" style={{width:"100%"}}>I understand the consequences, delete this subcategory</button>
    </p>
    </Modal.Body> 
    

</Modal>
</>
    
  );
}

CategoryDashBoard.propTypes ={
 ViewSubcategories: propTypes.func.isRequired
};
const mapStateToProps =(state) =>({
  auth: state.auth,
  errors: state.errors,
  items: state.items,
  subcategories: state.subcategories
});

export default connect(mapStateToProps, {ViewSubcategories, DeleteSubCategory})(CategoryDashBoard);
